import { Formik, Field, Form, FormikHelpers } from "formik";
import clsx from "clsx";
import { Modal } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import React, { useCallback, useState } from "react";
import { useAppStore } from "@/hooks";
import DemandaLogoLext from "@/assets/icons/demanda-logo-text.svg";
import DemandaLogo from "@/assets/icons/demanda-logo.svg";
import { IoIosArrowDropupCircle } from "react-icons/io";
import DemandaMessage from "@/components/shared/DemandaAI/DemandaMessage";

import styles from "./DemandaAI.module.scss";
import { IDemandaAIStore } from "@/interfaces/demanda-ai-store.interface";
import { getDemandAiMessages } from "@/api/get";
import { createCompletionStream } from "@/api/post";
import { IAppStore } from "@/interfaces/app-store.interface";
import CircularProgress from "@mui/material/CircularProgress";
import useDemandaAIStore from "@/hooks/useDemandaAIStore";
import { PointedTooltip } from "@/components/shared/PointedTooltip";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";

interface IDemandaAIProps {
  active: boolean;
}

const DemandaAI = ({ active }: IDemandaAIProps) => {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const isMobile = useMediaQuery({ query: `(max-width: 576px)` });

  const { selectedPidObject, setShowAlertBanner } = useAppStore(
    (store: IAppStore) => ({
      selectedPidObject: store.selectedPidObject,
      setShowAlertBanner: store.setShowAlertBanner
    })
  );

  const {
    messages,
    setMessages,
    setMessage,
    updateLastMessage
  }: IDemandaAIStore = useDemandaAIStore((store: IDemandaAIStore) => ({
    threadId: store.threadId,
    setThreadId: store.setThreadId,
    messages: store.messages,
    setMessages: store.setMessages,
    setMessage: store.setMessage,
    updateLastMessage: store.updateLastMessage
  }));

  const onClose = useCallback(() => {
    setShow(false);
  }, []);

  const toggleModal = useCallback(() => {
    const fetchData = async () => {
      setLoading(true);
      await getDemandAiMessages("1")
        .then((response) => {
          setMessages(response);
        })
        .catch((e) => {
          console.error("error:", e);
          setShowAlertBanner(true, {
            message: `Error fetching Topic Authority, please try again. If problem persists please contact customer success.`,
            severity: "error"
          });
        });

      setLoading(false);
    };

    if (selectedPidObject?.id) {
      if (!messages.length) {
        fetchData();
      }
    }

    setShow(true);
  }, [messages, selectedPidObject?.id, setMessages, setShowAlertBanner]);

  const onSubmitMessage = useCallback(
    async (
      values: { message: string },
      { setSubmitting, setFieldValue }: FormikHelpers<{ message: string }>
    ) => {
      const response = await createCompletionStream(values.message);
      // const response = await createStream(values.message);
      const decoder = new TextDecoderStream();

      if (response.body) {
        const reader = response.body.pipeThrough(decoder).getReader();
        let chunk = 1;
        // eslint-disable-next-line no-constant-condition
        while (true) {
          const { value, done } = await reader.read();
          if (done) {
            break;
          } else {
            if (chunk === 1) {
              setMessage({
                role: "user",
                messageId: "1",
                message: value
              });
            } else {
              updateLastMessage({
                role: "user",
                messageId: "1",
                message: value
              });
            }

            chunk++;
          }
        }
      } else {
        setMessage({
          role: "user",
          messageId: "1",
          message: "Error"
        });
      }

      setFieldValue("message", "");
      setSubmitting(false);
    },
    [setMessage, updateLastMessage]
  );

  return active ? (
    <div>
      <Modal
        show={show}
        onHide={onClose}
        className={styles.modal}
        dialogClassName={styles.dialog}
        contentClassName={styles.content}
      >
        <Modal.Header className={styles.header} closeButton={isMobile}>
          <img src={DemandaLogoLext} />
        </Modal.Header>
        <Modal.Body className={styles.modalBody}>
          {loading ? (
            <div className="d-flex justify-content-center">
              <CircularProgress />
            </div>
          ) : (
            messages.map((message, index) => (
              <DemandaMessage
                role={message.role}
                text={message.message}
                key={index}
              />
            ))
          )}
        </Modal.Body>
        <Modal.Footer className={styles.footer}>
          <Formik
            initialValues={{
              message: ""
            }}
            onSubmit={onSubmitMessage}
          >
            {({ isSubmitting }) => (
              <Form>
                <Field
                  id="message"
                  name="message"
                  type="text"
                  className={clsx("form-control", styles.input)}
                  placeholder="Message Demanda"
                  disabled={isSubmitting}
                />
                {isSubmitting ? null : (
                  <IoIosArrowDropupCircle
                    className={styles.inputIcon}
                    onClick={() => console.log("submit")}
                  />
                )}
              </Form>
            )}
          </Formik>
        </Modal.Footer>
      </Modal>
      <div className={styles.btnBlock}>
        <div onClick={toggleModal} className={styles.aiBlock}>
          <img src={DemandaLogoLext} className={styles.imgText} />
          <img src={DemandaLogo} className={styles.imgLogo} />
          <PointedTooltip
            title="This is some help information"
            placement="bottom"
          >
            <QuestionMarkIcon className={styles.helpBtn} />
          </PointedTooltip>
        </div>
      </div>
    </div>
  ) : null;
};

export default DemandaAI;
