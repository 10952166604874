import React, { memo, useCallback, useMemo } from "react";
import clsx from "clsx";
import { Container, Col, Row } from "react-bootstrap";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import RankingIconDark from "@/assets/icons/ranking-star-dark.svg";
import CompetitorIconDark from "@/assets/icons/competitors-dark.svg";
import {
  DataOrderType,
  IDataNetworkItem,
  IDataPriorityItem,
  IDataRankingItem
} from "@/interfaces/topic-authority.interface";
import { getDataOrderStatusClassName } from "@/utilities/getters";
import { toUpperCaseFirstLetter } from "@/utilities/format";

import styles from "./DataOrder.module.scss";

interface IDataOrderRowProps {
  item: IDataRankingItem | IDataPriorityItem | IDataNetworkItem;
  type?: DataOrderType;
}

function DataOrderRow({ item, type }: IDataOrderRowProps) {
  // TODO need to think about this implementation
  const getRankingParameters = useCallback(
    (item: IDataRankingItem) => (
      <>
        <img
          src={RankingIconDark}
          alt="your ranking icon"
          style={{ paddingRight: "4px" }}
        ></img>
        <span>{item?.ranking?.from}</span>
        <KeyboardArrowRightIcon fontSize="small" />
        <span>{item?.ranking?.to}</span>
      </>
    ),
    []
  );

  const getPriorityParameters = useCallback(
    (item: IDataPriorityItem) => (
      <>
        <span
          className={styles[getDataOrderStatusClassName(item?.priority?.from)]}
        >
          {toUpperCaseFirstLetter(item?.priority?.from)}
        </span>
        <KeyboardArrowRightIcon fontSize="small" />
        <span
          className={styles[getDataOrderStatusClassName(item?.priority?.to)]}
        >
          {toUpperCaseFirstLetter(item?.priority?.to)}
        </span>
      </>
    ),
    []
  );

  const getNetworkParameters = useCallback(
    (item: IDataNetworkItem) => (
      <>
        <img
          src={RankingIconDark}
          alt="ranking"
          style={{ paddingRight: "4px" }}
        ></img>
        <span>{item?.network?.ranking}</span>
        <img
          src={CompetitorIconDark}
          alt="competitors"
          style={{ paddingRight: "4px", paddingLeft: "8px" }}
        ></img>
        <span>{item?.network?.competitors}</span>
      </>
    ),
    []
  );

  const changingParameters = useMemo(() => {
    switch (type) {
      case "ranking":
        return getRankingParameters(item as IDataRankingItem);
      case "priority":
        return getPriorityParameters(item as IDataPriorityItem);
      case "network":
        return getNetworkParameters(item as IDataNetworkItem);
      default:
        return null;
    }
  }, [
    getNetworkParameters,
    getPriorityParameters,
    getRankingParameters,
    item,
    type
  ]);

  return (
    <div className={styles.rowContainer}>
      <Container>
        <Row>
          <Col
            xl={item?.status ? 6 : 8}
            xs={item?.status ? 6 : 8}
            className="d-flex align-items-center"
          >
            {item?.text}
          </Col>
          <Col
            xl={4}
            xs={4}
            className={clsx(
              "d-flex align-items-center justify-content-end",
              styles.changingParameters,
              styles.status
            )}
          >
            {changingParameters}
          </Col>
          {item?.status && (
            <Col
              xl={2}
              xs={2}
              className={clsx(
                "d-flex align-items-center justify-content-end",
                styles.status
              )}
            >
              <span
                className={styles[getDataOrderStatusClassName(item?.status)]}
              >
                {toUpperCaseFirstLetter(item?.status)}
              </span>
            </Col>
          )}
        </Row>
      </Container>
    </div>
  );
}

export default memo(DataOrderRow);
