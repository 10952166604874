import React, { memo } from "react";
import clsx from "clsx";

interface IDataNotFoundProps {
  className?: string;
}

function DataNotFound({ className }: IDataNotFoundProps) {
  return <div className={clsx("m-5", className)}>Data not found</div>;
}

export default memo(DataNotFound);
