/* eslint-disable max-lines */
import { useEffect, useState } from "react";
import Popover from "@mui/material/Popover";
import { NavigationArrowDown } from "@/assets/icons/svgr";
import AdvancedOptions from "./AdvancedOptions";
import {
  IInsightsMorpheusRequest,
  IInsights,
  IPendingInsight,
  IInsightsStore
} from "../../../interfaces/insights.interface";
import { IPDomain } from "../../../interfaces/pdomain.interface";
import { IAppStore } from "../../../interfaces/app-store.interface";
import { postInsight } from "../../../api/post";
import useAppStore from "../../../hooks/useAppStore";
import useInsightsStore from "../../../hooks/useInsightsStore";
import { getPidSubscription } from "../../../api/get";
import isEmpty from "lodash/isEmpty";
import { ICbSubscription } from "../../../interfaces/subscription.interface";
import { useCompetitorsStore } from "@/hooks";
import { ICompetitorsStore } from "@/interfaces/competitors-store.interface";
import { IErrorInsightArray } from "@/interfaces/insights.interface";
import { languageConvert } from "../utilities/language";

interface IProps {
  errorArr: IErrorInsightArray[];
  updateErrorArr: (errorItem: IErrorInsightArray[]) => void;
}

export default function GenerateInsight({ updateErrorArr, errorArr }: IProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [inputFocused, setInputFocused] = useState(false);
  const [listen, setListen] = useState(false);

  const {
    selectedPidObject,
    selectedSubscriptionObject,
    setSelectedSubscriptionObject,
    user,
    setShowAlertBanner
  } = useAppStore((store: IAppStore) => ({
    selectedPidObject: store.selectedPidObject,
    user: store.user,
    selectedSubscriptionObject: store.selectedSubscriptionObject,
    setSelectedPidObject: (value: IPDomain) =>
      store.setSelectedPidObject(value),
    setSelectedSubscriptionObject: (value: ICbSubscription) =>
      store.setSelectedSubscriptionObject(value),
    setShowAlertBanner: store.setShowAlertBanner
  }));
  const {
    insights,
    pendingInsights,
    setInsights,
    setPendingInsights,
    unfinishedInsights,
    setUnfinishedInsights
  } = useInsightsStore((store: IInsightsStore) => ({
    insights: store.insights,
    pendingInsights: store.pendingInsights,
    unfinishedInsights: store.unfinishedInsights,

    setInsights: (value: IInsights[]) => store.setInsights(value),
    setPendingInsights: (value: IPendingInsight[]) =>
      store.setPendingInsights(value),
    setUnfinishedInsights: (value: number[]) =>
      store.setUnfinishedInsights(value)
  }));

  const { competitors } = useCompetitorsStore((store: ICompetitorsStore) => ({
    competitors: store.competitors
  }));

  const [insightRequest, setInsightRequest] =
    useState<IInsightsMorpheusRequest>({
      pid: 0,
      query_string: "",
      location_name: "United States",
      language: "en",
      override_domain: "",
      override_competitors: "",
      user_id: user.id,
      user_name: user.name,
      user_email: user.unique_id,
      notify_user: false
    });
  const [submitError, setSubmitError] = useState(false);
  const [isProspecting, setIsProspecting] = useState(false);

  useEffect(() => {
    const fetchSubscription = async (pid: number) => {
      const response = await getPidSubscription(pid);
      setSelectedSubscriptionObject(response[0]);
      const isProspectingPid = response[0]?.cb_plan_id?.includes("prospecting");
      setIsProspecting(isProspectingPid);
    };

    if (selectedPidObject.id) {
      if (
        isEmpty(selectedSubscriptionObject) ||
        selectedPidObject.id !== insightRequest.pid
      ) {
        fetchSubscription(selectedPidObject.id);
      }
      setInsightRequest((prevState) => ({
        ...prevState,
        pid: selectedPidObject.id
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listen, inputFocused, selectedPidObject]);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : "";

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setInsightRequest((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    const thisInsight = insightRequest;

    if (
      thisInsight.query_string.length > 0 &&
      ((!insightRequest.override_domain &&
        !insightRequest.override_competitors) ||
        (insightRequest.override_domain && insightRequest.override_competitors))
    ) {
      setSubmitError(false);
      setInsightRequest((prevState) => ({
        ...prevState,
        query_string: "",
        language: "en",
        location_name: "United States",
        override_domain: "",
        override_competitors: "",
        user_id: user.id,
        user_email: user.unique_id,
        user_name: user.name,
        notify_user: true
      }));
      setShowAlertBanner(true, {
        message: "Creating Insight",
        severity: "success"
      });
      setPendingInsights([
        { ...insightRequest, created_at: Date(), status: "pending" },
        ...pendingInsights
      ]);
      const formattedInsightRequest: IInsightsMorpheusRequest = {
        ...insightRequest,
        query_string: insightRequest.query_string.trim()
      };
      await postInsight({
        ...formattedInsightRequest,
        user_id: user.id,
        user_email: user.unique_id,
        user_name: user.name,
        notify_user: true
      })
        .then((resp) => {
          const newInsight = resp.data?.result;
          setUnfinishedInsights([...unfinishedInsights, newInsight.cbm_id]);
          setPendingInsights(
            pendingInsights.filter(
              (insight: IInsights) =>
                insight.query_string !== thisInsight.query_string
            )
          );
          setInsights([
            {
              id: newInsight.cbm_id,
              query_string: newInsight.query,
              language: newInsight.language,
              location_name: newInsight.location_name,
              location_id: newInsight.location_id,
              pid: newInsight.pid,
              created_at: Date(),
              status: "pending"
            },
            ...insights.filter(
              (i: IInsights) => i.query_string !== newInsight.query
            )
          ]);
        })
        .catch((error) => {
          console.error(error);
          const errMsg = error.response.data.error;
          setPendingInsights(
            pendingInsights.filter(
              (insight: IInsights) =>
                insight.query_string !== thisInsight.query_string
            )
          );
          if (errMsg === "Duplicate Insight") {
            updateErrorArr([
              {
                ...thisInsight,
                language: languageConvert(thisInsight.language),
                created_at: new Date(),
                status: "ERROR-DUP"
              },
              ...errorArr
            ]);
            setShowAlertBanner(true, {
              message:
                "You have already run this phrase, please wait at least 1 hour before running the same phrase again.",
              severity: "error"
            });
          } else if (errMsg === "preflight") {
            setShowAlertBanner(true, {
              message: "No results - please try another phrase",
              severity: "error"
            });
            updateErrorArr([
              {
                ...thisInsight,
                created_at: new Date(),
                status: "ERROR-PRE"
              },
              ...errorArr
            ]);
          } else {
            setShowAlertBanner(true, {
              message:
                "There was an error running your phrase - please try another phrase",
              severity: "error"
            });
            updateErrorArr([
              {
                ...thisInsight,
                created_at: new Date(),
                status: "ERROR-ERR"
              },
              ...errorArr
            ]);
          }
        });
    } else if (
      thisInsight.query_string.length > 0 &&
      !insightRequest.override_domain &&
      insightRequest.override_competitors
    ) {
      setShowAlertBanner(true, {
        message: "Prospect domain is required",
        severity: "error"
      });
    } else if (
      thisInsight.query_string.length > 0 &&
      insightRequest.override_domain &&
      !insightRequest.override_competitors
    ) {
      setShowAlertBanner(true, {
        message: "Prospect competitor domain is required",
        severity: "error"
      });
    } else {
      setSubmitError(true);
      setInsightRequest((prevState) => ({
        ...prevState,
        query_string: "",
        language: "en",
        location_name: "United States",
        override_domain: "",
        override_competitors: ""
      }));
      setShowAlertBanner(true, {
        message: "No results - please try another phrase",
        severity: "error"
      });
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setInputFocused(false);
    setListen(false);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setInputFocused(true);
  };

  return (
    <div className="generate-parent">
      <form className="generate-insight-container" onSubmit={handleSubmit}>
        <div
          className={`generate-input-dropdown ${
            inputFocused ? "focused" : ""
          } ${submitError ? "error" : ""}`}
        >
          <input
            className={`insight-input ${inputFocused ? "focused" : ""} ${
              submitError ? "error" : ""
            }`}
            type="text"
            placeholder={submitError ? "invalid entry" : "ex: running shoes"}
            onFocus={() => setInputFocused(true)}
            onBlur={() => setInputFocused(false)}
            onChange={handleChange}
            name="query_string"
            value={insightRequest.query_string}
          />
          <button
            type="button"
            className={`dropdown-button ${submitError ? "error" : ""}`}
            aria-describedby={id}
            onClick={handleClick}
          >
            Customize Report{" "}
            <NavigationArrowDown
              style={{ width: "24px", marginLeft: "15px" }}
            />
          </button>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
          >
            <AdvancedOptions
              isProspecting={isProspecting}
              insightRequest={insightRequest}
              setInsightRequest={setInsightRequest}
            />
          </Popover>
        </div>
        <button
          className="generate-button"
          type="submit"
          onClick={handleSubmit}
          disabled={competitors.length === 0}
        >
          Generate
        </button>
      </form>
    </div>
  );
}
