import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";

export const PointedTooltip = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  )
)(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#22282D"
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#22282D",
    padding: "8px"
  }
}));
