import React from "react";
import PercentageChange from "@/components/topic_authority/components/PercentageChange";
import ScoreChart from "@/components/topic_authority/components/ScoreChart";
import ScoreNumber from "@/components/topic_authority/components/ScoreNumber";
import DataNotFound from "@/components/topic_authority/components/DataOrder/DataNotFound";
import { ITopicAuthority } from "@/interfaces/topic-authority.interface";

import styles from "./TopicAuthorityContent.module.scss";

interface ITopicAuthorityScoreProps {
  topic: ITopicAuthority;
}

function TopicAuthorityScore({ topic }: ITopicAuthorityScoreProps) {
  return (
    <div className={styles.taScoreContainer}>
      {topic?.score ? (
        <>
          <div className={styles.taScoreParams}>
            <ScoreChart
              value={Math.round(topic?.score?.topicalAuthorityScore?.value)}
              type={topic?.score?.topicalAuthorityScore?.type}
              className={styles.taContentHeader}
            />
            <ScoreNumber value={topic?.score?.lastWeek} />
            <PercentageChange
              value={topic?.score?.percentageChange?.value}
              type={topic?.score?.percentageChange?.type}
            />
          </div>
          <a
            href="https://www.demandjump.com/legal"
            target="_blank"
            className={styles.note}
          >
            How is my score calculated?
          </a>
        </>
      ) : (
        <DataNotFound />
      )}
    </div>
  );
}

export default TopicAuthorityScore;
