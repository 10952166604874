import React, {
  memo,
  useRef,
  useState,
  useCallback,
  useEffect,
  useMemo
} from "react";
import clsx from "clsx";
import CircularProgress from "@mui/material/CircularProgress";
import { NavigationArrowLeft, NavigationArrowRight } from "@/assets/icons/svgr";

import styles from "./Tabs.module.scss";

export type ButtonOnClick = (
  stopLoading: () => void,
  index: number
) => Promise<void> | void;

interface ITabItem {
  title: string;
}

interface ITabsProps {
  items: ITabItem[];
  onClick: ButtonOnClick;
  showLoading?: boolean;
  selectedIndex?: number;
  tabWidth?: number;
  tabHeight?: number;
  isDynamicSizes?: boolean;
  isStaticHeight?: boolean;
  classes?: {
    carouselContainer?: string;
    scrollBtn?: string;
    carousel?: string;
    tab?: string;
  };
}

function Tabs({
  items,
  onClick,
  showLoading = false,
  selectedIndex,
  tabWidth = 300,
  tabHeight = 90,
  isDynamicSizes = false,
  isStaticHeight = false,
  classes
}: ITabsProps) {
  // USE STATE DECLARATIONS
  const [isLoading, setLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [visibleTabs, setVisibleTabs] = useState(3);
  const [showLeftScrollBtn, setShowLeftScrollBtn] = useState(false);

  const carouselRef = useRef<HTMLDivElement>(null);

  // if there is a selectedTopicIndex on pageload, scroll to that tab
  // this is for when a new pillar was just added from the looker dashboard
  useEffect(() => {
    if (carouselRef?.current?.scrollBy && selectedIndex) {
      carouselRef.current.scrollBy({
        left: selectedIndex * tabWidth
      });
    }

    // highlight the selected tab
    if (selectedIndex || selectedIndex === 0) {
      setSelectedTab(selectedIndex);
      setShowLeftScrollBtn((carouselRef.current?.scrollLeft || 0) > 0);
    }
  }, [selectedIndex, tabWidth]);

  useEffect(() => {
    if (items.length > 3) {
      setVisibleTabs(4);
    }
  }, [items]);

  const stopLoading = useCallback(() => {
    setLoading(false);
  }, []);

  const handleTabClick = useCallback(
    async (index: number): Promise<void> => {
      if (onClick) {
        setSelectedTab(index);
        if (showLoading) {
          setLoading(true);
          await onClick(stopLoading, index);
        } else {
          await onClick(() => false, index);
        }
      }
    },
    [onClick, showLoading, stopLoading]
  );

  const handleScrollClick = useCallback(
    (direction: "forward" | "back") => {
      const scrollAmount = direction === "forward" ? tabWidth : tabWidth * -1;

      if (carouselRef?.current?.scrollBy) {
        carouselRef.current.scrollBy({
          left: scrollAmount
        });
      }
      setShowLeftScrollBtn((carouselRef.current?.scrollLeft || 0) > 0);
    },
    [tabWidth]
  );

  const handleScroll = useCallback(
    (event: React.WheelEvent<HTMLDivElement>) => {
      event.stopPropagation();
      setShowLeftScrollBtn(
        document.getElementById("carousel")?.scrollLeft !== 0
      );
      const scrollAmount = event.deltaY;
      if (carouselRef?.current?.scrollBy) {
        carouselRef.current.scrollBy({
          left: scrollAmount
        });
      }
    },
    []
  );

  const carouselTabStyle = useMemo(
    () =>
      isDynamicSizes
        ? { ...(isStaticHeight ? { height: `${tabHeight}px` } : {}) }
        : {
            minWidth: `${tabWidth}px`,
            maxWidth: `${tabWidth}px`,
            height: `${tabHeight}px`
          },
    [isDynamicSizes, tabWidth, tabHeight]
  );

  return (
    <div className={clsx(styles.carouselContainer, classes?.carouselContainer)}>
      {/* // if the carouselRef is scrolled by some offset then display the scroll button */}
      {items.length > visibleTabs && showLeftScrollBtn && (
        <button
          className={clsx(styles.scrollBtn, classes?.scrollBtn)}
          onClick={() => handleScrollClick("back")}
        >
          {<NavigationArrowLeft className={styles.navigationArrow} />}
        </button>
      )}
      <div
        className={clsx(styles.carousel, classes?.carousel)}
        id="carousel"
        ref={carouselRef}
        onWheelCapture={(event) => handleScroll(event)}
      >
        {items.map((item, index) => (
          <div
            key={index}
            className={clsx(
              styles.carouselTab,
              selectedTab === index && styles.selected,
              !(index === items.length - 1 && visibleTabs <= 4) &&
                styles.carouselTabNonBorderRight,
              classes?.tab
            )}
            style={carouselTabStyle}
            onClick={() => selectedTab !== index && handleTabClick(index)}
          >
            {showLoading && isLoading && index === selectedTab ? (
              <CircularProgress className={styles.tabLoader} />
            ) : null}
            {item.title}
          </div>
        ))}
      </div>
      {items.length > visibleTabs && (
        <button
          className={clsx(styles.scrollBtn, classes?.scrollBtn)}
          onClick={() => handleScrollClick("forward")}
        >
          {<NavigationArrowRight className={styles.navigationArrow} />}
        </button>
      )}
    </div>
  );
}

export default memo(Tabs);
