/* eslint-disable prettier/prettier */
/* eslint-disable max-lines */
/* eslint-disable no-mixed-operators */
/* eslint-disable complexity */
import { useEffect, useState } from "react";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import PropTypes from "prop-types";
import { Formik, Form, Field } from "formik";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import {
  getUpdatedPillars,
  getChangedPillars,
  customSelectLocationsStyles,
  limitPillars,
  getDeletedPillars
} from "./common/utils";
import UpgradeSubscriptionModal from "./components/UpgradeSubscriptionModal";
import {
  getCompetitorsByPid,
  getEntitlements,
  getPillars
} from "../../../api/get";
import LocationSelect from "../../shared/LocationSelect";
import LoadingCard from "./components/LoadingCard";
import ContentPillarsHeader from "./components/ContentPillarsHeader";
import DetailWrapper from "./components/DetailWrapper";
import ConfirmUpdateModal from "./components/ConfirmUpdateModal";
import IconBarchart from "../../../assets/icons/icon-barchart.svg";
import AfterLocationSelect from "./components/AfterLocationSelect";
import {
  useAppStore,
  useCompetitorsStore,
  useEffectAsync
} from "../../../hooks";
import "./ContentPillars.scss";
import "./common/contentPillars.scss";
import { postContentPillars } from "../../../api/post";
import RetrieveWorkingPillars from "./components/RetrieveWorkingPillars";
import NoCompetitorsComponent from "@/components/insights/components/NoCompetitorsComponent";

dayjs.extend(utc);

const ContentPillars = () => {
  const [contentPillars, setContentPillars] = useState([]);
  const [contentPillarsLimit, setContentPillarsLimit] = useState(null);
  const [incompletePillars, setIncompletePillars] = useState([]);
  const [initialValues, setInitialValues] = useState({});
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const { selectedPidObject, setShowAlertBanner, user } = useAppStore(
    (store) => ({
      selectedPidObject: store.selectedPidObject,
      setShowAlertBanner: store.setShowAlertBanner,
      user: store.user
    })
  );

  const { competitors, setCompetitors } = useCompetitorsStore((store) => ({
    competitors: store.competitors,
    setCompetitors: (value) => store.setCompetitors(value)
  }));

  useEffectAsync(async () => {
    if (selectedPidObject.id) {
      setLoading(true);
      // get competitors from api
      const response = await getCompetitorsByPid(selectedPidObject.id);
      setCompetitors(response);
      setLoading(false);
    }
  }, [selectedPidObject]);

  const djEmployee = user?.dj_employee;

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black
    }
  }));

  const inputStyles = {
    fontSize: "16px",
    backgroundColor: "white",
    paddingRight: "40px"
  };
  const errorInputStyle = {
    fontSize: "16px",
    border: "1px solid #b00020",
    color: "#b00020",
    backgroundColor: "white"
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (selectedPidObject.id) {
          const entitlements = await getEntitlements(selectedPidObject.id);
          setContentPillarsLimit(entitlements?.limits?.content_pillars);
          const pillarData = await getPillars(selectedPidObject.id);
          setContentPillars(pillarData);
        }
      } catch (e) {
        setShowAlertBanner(true, {
          message:
            "An error occurred while fetching your pillar topics. Please try again.",
          severity: "error"
        });
        console.error("error:", e);
      }
      setLoading(false);
    };
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPidObject]);

  const numRows = contentPillarsLimit < 5 ? 5 : contentPillarsLimit;

  //  create initialValues with previous X keywords and empty strings for 5 - X possible keywords where X = previous # of keywords
  useEffect(() => {
    if (contentPillarsLimit !== null && contentPillars !== null) {
      const tempInitialValues = {};
      const tempIncompletePillars = [];
      [...Array(numRows).keys()].forEach((i) => {
        tempInitialValues[`keyword${i + 1}`] = contentPillars[i]
          ? {
              ...contentPillars[i],
              location: {
                value: contentPillars[i].location,
                label: contentPillars[i].location
              }
            }
          : {
              keyword: "",
              location: {
                value: "United States",
                label: "United States"
              },
              updated_at: ""
            };
        if (
          contentPillars[i] &&
          ["pending", "working"].includes(contentPillars[i].cbmInfo?.status)
        ) {
          tempIncompletePillars.push(contentPillars[i]);
        }
      });
      setInitialValues(tempInitialValues);
      setIncompletePillars(tempIncompletePillars);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentPillarsLimit, contentPillars]);

  const handleSubmitFunction = (values) => {
    setSaving(true);
    const updatedValues = getUpdatedPillars(initialValues, values);
    updatedValues.forEach((pillar, i) => {
      if (typeof pillar.location !== "string") {
        updatedValues[i].location = pillar.location.value;
      }
      if (pillar.isUpdatedPillar) {
        updatedValues[i].updated_at = Date.now;
      }
    });
    const updatedKeywords = updatedValues.map((pillar) =>
      pillar.keyword.trim()
    );
    const deletedKeywords = getDeletedPillars(contentPillars, updatedKeywords);
    // only allow saving of X number of pillars where X = contentPillarLimit.
    // This is necessary for users that used to have a higher limit than they currently do.
    const limitedPillars = limitPillars(updatedValues, contentPillarsLimit);

    const finalPayload = { pillars: limitedPillars, deletedKeywords };

    postContentPillars(finalPayload, selectedPidObject.id)
      .then((response) => {
        setContentPillars(response);
        setShowAlertBanner(true, {
          message: "Your pillar topics have been updated.",
          severity: "success"
        });
        setSaving(false);
      })
      .catch((error) => {
        console.error("error", error);
        setShowAlertBanner(true, {
          message:
            "An error occurred while saving your pillar topics. Please try again.",
          severity: "error"
        });
        setSaving(false);
      });
  };

  if (!loading && contentPillarsLimit === 0) {
    return (
      <div className="justify-content-center align-center">
        <div
          className="d-flex justify-content-center align-center"
          style={{
            zIndex: 3,
            textAlign: "center",
            position: "absolute",
            float: "left",
            left: "50%",
            top: "55%",
            transform: "translate(-50%, -50%)",
            height: "188px",
            width: "357px"
          }}
        >
          <UpgradeSubscriptionModal />
        </div>
        <LoadingCard contentPillarsLimit={contentPillarsLimit} />
      </div>
    );
  }

  if (loading || Object.keys(initialValues).length === 0) {
    return (
      <>
        <div
          className="spinner-border"
          role="status"
          style={{
            position: "absolute",
            zIndex: 3,
            float: "left",
            left: "50%",
            top: "55%"
          }}
        ></div>
        <LoadingCard />
      </>
    );
  }

  return (
    <div id="contentPillars" className="mtlr-30" style={{ height: "80vh" }}>
      {saving && (
        <div
          className="d-flex justify-content-center position-fixed h-100 align-items-center"
          style={{ zIndex: 2 }}
        >
          <div className="spinner-border" role="status">
            <span className="sr-only"></span>
          </div>
        </div>
      )}
      <div style={{ padding: "20px 22px" }}>
        <ContentPillarsHeader />
        {contentPillarsLimit && !isEmpty(initialValues) ? (
          <>
            <DetailWrapper />
            {competitors.length > 0 ? (
              <div
                className="form-wrapper"
                style={{ marginBottom: "0" }}
                id="contentPillarsFormWrapper"
              >
                <Formik
                  enableReinitialize
                  initialValues={initialValues}
                  onSubmit={(values) => handleSubmitFunction(values)}
                >
                  {({
                    values,
                    handleSubmit,
                    setFieldValue,
                    setValues,
                    touched,
                    dirty
                  }) => (
                    <Form
                      className="form-wrapper"
                      style={{ marginBottom: "0" }}
                    >
                      <>
                        <ConfirmUpdateModal
                          visible={confirmModalVisible}
                          setVisible={setConfirmModalVisible}
                          keywords={getChangedPillars(initialValues, values)}
                          handleSubmit={handleSubmit}
                        />
                        {Array.from(Array(numRows).keys()).map((int) => {
                          const isLessThan30DaysOld =
                            values[`keyword${int + 1}`]?.updated_at &&
                            dayjs()
                              .utc()
                              .diff(
                                dayjs(values[`keyword${int + 1}`]?.updated_at),
                                "day"
                              ) < 30;
                          const isError =
                            values[
                              `keyword${int + 1}`
                            ].cbmInfo?.status.includes("ERROR");
                          const isNoDataError =
                            values[`keyword${int + 1}`].cbmInfo?.status ===
                            "ERROR-PRE";
                          const showUpgrade = int + 1 > contentPillarsLimit;
                          const showReadyToUpdateText =
                            !isEqual(
                              values[`keyword${int + 1}`].keyword,
                              initialValues[`keyword${int + 1}`].keyword
                            ) ||
                            (!isEqual(
                              values[`keyword${int + 1}`].location,
                              initialValues[`keyword${int + 1}`].location
                            ) &&
                              values[`keyword${int + 1}`].keyword.length > 0);
                          return (
                            <div
                              key={int}
                              className="content-pillars-control row skeleton pb-3"
                            >
                              <div className="col-6">
                                <div className="inputWrapper">
                                  <label
                                    className="col-form-label"
                                    style={{
                                      fontSize: "16px",
                                      height: "24px",
                                      fontFamily: "source-sans-pro, sans-serif",
                                      color:
                                        isError && !showReadyToUpdateText
                                          ? "#b00020"
                                          : "#373f50"
                                    }}
                                  >
                                    {int + 1}.
                                  </label>

                                  <Field
                                    disabled={
                                      (!djEmployee &&
                                        values[`keyword${int + 1}`]
                                          ?.updated_at !== "") ||
                                      showUpgrade
                                    }
                                    name={`keyword${int + 1}.keyword`}
                                    type="text"
                                    style={
                                      isError && !showReadyToUpdateText
                                        ? errorInputStyle
                                        : inputStyles
                                    }
                                    className="form-control content-pillars-textfield"
                                    placeholder="ex: running shoes"
                                  />
                                  {!isError &&
                                    values[`keyword${int + 1}`]?.cbmInfo
                                      ?.status === "complete" && (
                                      <BootstrapTooltip
                                        title="View Pillar Summary"
                                        placement="right"
                                      >
                                        <img
                                          className="barchart-icon"
                                          src={IconBarchart}
                                          onClick={() =>
                                            window.open(
                                              `/dashboard/pillar-summary?pid=${
                                                selectedPidObject.id
                                              }&Blocklist+Filter=no&Topic=${values[
                                                `keyword${int + 1}`
                                              ].keyword.replace(" ", "+")}`
                                            )
                                          }
                                        />
                                      </BootstrapTooltip>
                                    )}
                                </div>
                                {!showReadyToUpdateText &&
                                  !isNoDataError &&
                                  values[`keyword${int + 1}`].keyword?.length >
                                    0 && (
                                    <small
                                      className="form-text text-muted col-10 position-absolute"
                                      style={{
                                        marginLeft: "30px",
                                        marginTop: "-5px",
                                        color: "#B00020"
                                      }}
                                    >
                                      Last updated:{" "}
                                      {dayjs(
                                        values[`keyword${int + 1}`]?.updated_at
                                      ).format("MM/DD/YY")}
                                    </small>
                                  )}
                                {showReadyToUpdateText && (
                                  <small
                                    className="form-text text-success col-10 position-absolute"
                                    style={{
                                      marginLeft: "28px",
                                      marginTop: "-5px"
                                    }}
                                  >
                                    Ready to update
                                  </small>
                                )}
                              </div>
                              <div className="locationWrapper col-5">
                                {loading ? (
                                  <div className="skeleton-shape" />
                                ) : (
                                  <LocationSelect
                                    isError={isError || isNoDataError}
                                    showReadyToUpdateText={
                                      showReadyToUpdateText
                                    }
                                    isClearable={true}
                                    isDisabled={
                                      (!djEmployee &&
                                        values[`keyword${int + 1}`]
                                          ?.updated_at !== "") ||
                                      showUpgrade
                                    }
                                    location={
                                      typeof values[`keyword${int + 1}`]
                                        .location === "string" ||
                                      values[`keyword${int + 1}`]
                                        .location instanceof String
                                        ? {
                                            value:
                                              values[`keyword${int + 1}`]
                                                .location,
                                            label:
                                              values[`keyword${int + 1}`]
                                                .location
                                          }
                                        : values[`keyword${int + 1}`].location
                                    }
                                    setLocation={() => null}
                                    parentCallback={(value) =>
                                      setFieldValue(`keyword${int + 1}`, {
                                        ...values[`keyword${int + 1}`],
                                        location: {
                                          value: value.location_name,
                                          label: value.location_name
                                        }
                                      })
                                    }
                                    style={customSelectLocationsStyles}
                                  />
                                )}
                              </div>
                              <AfterLocationSelect
                                touched={touched[`keyword${int + 1}.keyword`]}
                                values={values}
                                int={int}
                                isError={isError}
                                isNoDataError={isNoDataError}
                                djEmployee={djEmployee}
                                isLessThan30DaysOld={isLessThan30DaysOld}
                                showUpgrade={showUpgrade}
                                showReadyToUpdateText={showReadyToUpdateText}
                              />
                            </div>
                          );
                        })}
                        <div
                          className="d-flex justify-content-end"
                          style={{
                            padding: "30px 0 0 0"
                          }}
                        >
                          <button
                            id="cancel-btn"
                            type="button"
                            className="btn mx-3 cancel-button"
                            style={{ width: "128px", height: "40px" }}
                            onClick={() => {
                              const tempInitialValues = {};
                              [...Array(numRows).keys()].forEach((i) => {
                                tempInitialValues[`keyword${i + 1}`] =
                                  contentPillars[i]
                                    ? {
                                        ...contentPillars[i],
                                        location: {
                                          value: contentPillars[i].location,
                                          label: contentPillars[i].location
                                        }
                                      }
                                    : {
                                        keyword: "",
                                        location: {
                                          value: "United States",
                                          label: "United States"
                                        },
                                        updated_at: ""
                                      };
                              });
                              if (isEqual(tempInitialValues, values)) {
                                window.location = "/home";
                              } else {
                                setValues(tempInitialValues);
                              }
                            }}
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            onClick={() => {
                              setConfirmModalVisible(true);
                            }}
                            id="save-btn"
                            className="btn btn-primary demandjump-button-primary update-button"
                            style={{ width: "170px", height: "40px" }}
                            disabled={!dirty}
                          >
                            Update Pillars
                          </button>
                        </div>
                      </>
                      <RetrieveWorkingPillars
                        contentPillars={contentPillars}
                        incompletePillars={incompletePillars}
                        setIncompletePillars={setIncompletePillars}
                        setContentPillars={setContentPillars}
                        pid={selectedPidObject.id}
                      />
                    </Form>
                  )}
                </Formik>
              </div>
            ) : (
              <NoCompetitorsComponent page="Pillars" />
            )}
          </>
        ) : null}
      </div>
    </div>
  );
};

ContentPillars.propTypes = {
  isAdmin: PropTypes.bool
};

export default ContentPillars;
