import { useEffect, useState } from "react";
import load from "../../../assets/images/djLoading.gif";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import { ActionInfo } from "@/assets/icons/svgr";
import { streamingInsight } from "../../../interfaces/insights.interface";

export default function KeywordsTable() {
  const [loading, setLoading] = useState(true);
  const [keywordData, setKeywordData] = useState<streamingInsight[]>([]);

  const keywords = [
    {
      keyword: "blog posts",
      rank: 2,
      score: 88
    },
    {
      keyword: "marketing attribution",
      rank: 8,
      score: 92
    },
    {
      keyword: "content pillar",
      rank: 5,
      score: 90
    }
  ];
  useEffect(() => {
    setTimeout(() => {
      setKeywordData(keywords);
      setLoading(false);
    }, 10000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Table
      sx={{ width: "100%", backgroundColor: "rgba(233, 235, 240, 0.3)" }}
      aria-label="a dense table"
    >
      <TableHead style={{ boxShadow: "0px 4px 8px rgba(55,63,80,0.25)" }}>
        <TableRow>
          <TableCell className="pl-25" align="left" style={{ width: "47%" }}>
            Keywords and Questions
          </TableCell>
          <TableCell align="left" style={{ width: "13%" }}>
            <Tooltip
              arrow
              placement="right"
              title="The position your content ranks for this keyword or question."
            >
              <span>
                Rank
                <ActionInfo style={{ width: "18px", marginLeft: "2px" }} />
              </span>
            </Tooltip>
          </TableCell>
          <TableCell align="left" style={{ width: "40%" }}>
            <Tooltip
              arrow
              placement="right"
              title="A 1-100 rank score signifies the power of a phrase or question within the network, driven by its connectivity and page rank (relevancy)."
            >
              <span>
                DJ Score{" "}
                <ActionInfo style={{ width: "18px", marginLeft: "2px" }} />
              </span>
            </Tooltip>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody className={loading ? "loading" : ""}>
        {loading ? (
          <TableRow>
            <TableCell colSpan={3}>
              <div className="loader-container">
                <img src={load} alt="dj-gif" />
              </div>
            </TableCell>
          </TableRow>
        ) : (
          keywordData.map((keyword) => (
            <TableRow>
              <TableCell className="pl-25" align="left">
                {keyword.keyword}
              </TableCell>
              <TableCell align="left">{keyword.rank}</TableCell>
              <TableCell align="left">{keyword.score}</TableCell>
            </TableRow>
          ))
        )}
      </TableBody>
    </Table>
  );
}
