/* eslint-disable max-lines */
/* eslint-disable array-callback-return */
/* eslint-disable complexity */
/* eslint-disable max-depth */
import { useState, useEffect } from "react";
import "./../App.css";
import isEmpty from "lodash/isEmpty";

import { getAllInsights, getDashboardUrl, getPillars } from "../api/get";
import { useAppStore } from "../hooks";
import { addOrUpdateUrlParam } from "../routines/addOrUpdateUrl";

import "../components/iframe.scss";

import { useNavigate, useParams } from "react-router-dom";
import { IAppStore } from "../interfaces/app-store.interface";
import Cookies from "universal-cookie";
import {
  addBlogToPillarStrategy,
  addSubPillarToPillarStrategy
} from "@/api/post";

import { useMediaQuery } from "react-responsive";
import getUsableWidth from "@/routines/get-usable-width";

const cookies = new Cookies();

function Dashboard(): JSX.Element {
  const [handleMessageSetUp, setHandleMessageSetUp] = useState(false);
  const [loading, setLoading] = useState(true);
  const [URL, setURL] = useState("");
  const [topic, setTopic] = useState(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const topicFromUrl = urlSearchParams
      .get("Topic")
      ?.replace(/_/u, " ")
      .toLowerCase();
    const cookieTopic = cookies.get("Topic");
    return topicFromUrl || cookieTopic || "";
  });
  const [pillarTopic, setPillarTopic] = useState(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const pillarTopicFromUrl = urlSearchParams.get("PillarTopic");
    const cookiePillarTopic = cookies.get("PillarTopic");
    return pillarTopicFromUrl || cookiePillarTopic || "";
  });
  const [allInsights, setAllInsights] = useState([""]);
  const [allPillarTopics, setAllPillarTopics] = useState([""]);
  const [blocklist, setBlocklist] = useState(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const blockListFromUrl = urlSearchParams.get("BlocklistFilter");
    return blockListFromUrl || "";
  });
  const [iframe, setIframe] = useState(
    document.getElementById("looker") as HTMLIFrameElement
  );
  const params = useParams();
  const navigate = useNavigate();

  const {
    selectedPidObject,
    allowedUrls,
    setShowAlertBanner,
    user,
    sideMenuOpen
  } = useAppStore((store: IAppStore) => ({
    selectedPidObject: store.selectedPidObject,
    allowedUrls: store.allowedUrls,
    setShowAlertBanner: store.setShowAlertBanner,
    user: store.user,
    sideMenuOpen: store.sideMenuOpen
  }));
  const isMobile = useMediaQuery({ query: `(max-width: 1024px)` });

  const pid = selectedPidObject.id;
  const lookerInstance = process.env.REACT_APP_LOOKER_INSTANCE;
  const urlSearchParams = new URLSearchParams(window.location.search);
  const pillarTopicFromUrl = urlSearchParams.get("PillarTopic");
  const blockListFromUrl = urlSearchParams.get("BlocklistFilter");

  let notIncludedInsights: string[] = [];
  let notIncludedPillarArray: string[] = [];

  if (topic) {
    topic.replace(/_/u, " ").toLowerCase();
  }

  useEffect(() => {
    const iframeURL = document.getElementById("looker") as HTMLIFrameElement;
    if (URL) {
      setIframe(iframeURL);
    }
  }, [URL]);

  const fetchData = async () => {
    setLoading(true);
    if (pid) {
      const allInsightsData = await getAllInsights(pid);
      const insights: string[] = [];
      allInsightsData.map((item) => {
        if (item.query_string) {
          const insightFormatted = item.query_string
            .trim()
            .replace(/_/u, " ")
            .toLowerCase();
          insights.push(insightFormatted);
        }
        return item;
      });
      setAllInsights(insights);
      const allPillarsData = await getPillars(pid);
      const pillars: string[] = [];
      allPillarsData.map((item) => {
        if (item.keyword) {
          const pillarFormatted = item.keyword.toLowerCase();
          pillars.push(pillarFormatted);
        }
        return item;
      });
      setAllPillarTopics(pillars);

      if (topic) {
        topic.replace(/_/u, " ").toLowerCase();
      }
      const dashboard = window.location.pathname.split("/")[2];
      // formatting of topic & pillar topic to make api call.
      // Wait for insights array from api, wait for topic, if multiple topics we need to split. Map through the array and format. If the insight is not an active insight for the pid, add falsy to insightsIncludedTopic.
      let checkedTopic = topic;
      if (topic && insights.length > 1) {
        const formattedTopic = topic.replace(/_/u, " ").trim().toLowerCase();
        const topicSplit = topic.split(",");
        if (topicSplit.length > 1) {
          const insightsIncludeTopic = topicSplit.map((item: string) =>
            insights.includes(item.toLowerCase())
          );
          if (insights.length > 1 && insightsIncludeTopic.includes(false)) {
            checkedTopic = "";
            setTopic("");
          }
        } else if (insights.length > 1 && !insights.includes(formattedTopic)) {
          checkedTopic = "";
          setTopic("");
        }
      }
      let checkedPillarTopic = pillarTopic;
      if (pillarTopic) {
        const formattedPillarTopic = pillarTopic
          .replace(/_/u, " ")
          .trim()
          .toLowerCase();
        const pillarsIncludePillarTopic = formattedPillarTopic
          .split(",")
          .map((item: string) => pillars.includes(item.toLowerCase()));
        if (pillarsIncludePillarTopic.includes(false)) {
          checkedPillarTopic = "";
          setPillarTopic("");
          addOrUpdateUrlParam("PillarTopic", "");
        }
      }
      try {
        // api call to generate the url required for looker sso embedding
        const response = await getDashboardUrl(
          dashboard,
          pid,
          checkedTopic,
          checkedPillarTopic,
          blocklist
        );
        setURL(response.data.url);
        // if there isnt a topic or pillar topic is an empty string we grab the latest insight and set it as the topic(getStickyTopic)
        const lookerUrlParams = decodeURIComponent(
          decodeURI(response.data.url).split("?")[0].split("/")[5]
        )
          .split("?")[1]
          .split("&");
        const topicString = lookerUrlParams.filter(
          (param) =>
            param.includes("Topic=") && !param.includes("Pillar+Topic=")
        );
        const insightsIncludeTopic = topic
          .split(",")
          .map((item: string) => insights.includes(item.toLowerCase()));
        if (
          (!topic || topic === "") &&
          topicString.length &&
          insightsIncludeTopic.includes(false)
        ) {
          if (topicString.length > 0) {
            const newTopicString = topicString[0]
              .split("=")[1]
              .replace(/_/gu, " ")
              .toLowerCase();
            setTopic(newTopicString);
          }
        }
        const pillarTopicString = lookerUrlParams.filter((param) =>
          param.includes("Pillar+Topic=")
        );
        const pillarsIncludePillarTopic = pillarTopic
          .split(",")
          .map((item: string) => pillars.includes(item.toLowerCase()));

        if (
          (!pillarTopic || pillarTopic === "") &&
          pillarTopicString.length &&
          pillarsIncludePillarTopic.includes(false)
        ) {
          if (pillarTopicString.length > 0) {
            const newPillarTopicString = pillarTopicString[0]
              .split("=")[1]
              .replace(/ /gu, "+")
              .toLowerCase();
            setPillarTopic(newPillarTopicString);
          }
        }
        const blocklistString = lookerUrlParams.filter((param) =>
          param.includes("Blocklist+Filter=")
        );
        if ((!blocklist || blocklist === "") && blocklistString.length) {
          const newBlocklistString = blocklistString[0]
            .split("=")[1]
            .replace(/ /gu, "+")
            .toLowerCase();
          setBlocklist(newBlocklistString);
        }
      } catch (error) {
        console.error("Error fetching dashboard URL", error);
        navigate("/");
        setShowAlertBanner(true, {
          message: `Error fetching the dashboard, please try again. If problem persists please contact customer success.`,
          severity: "error"
        });
      } finally {
        setLoading(false);
      }
    }
  };

  // when the page loads we add an event listener so that when filters are changed in the looker dashboard the topic and pillar topic are updated in the url - required for bookmarks to function
  function handleMessage() {
    window.addEventListener("message", (event) => {
      if (event.origin === lookerInstance) {
        const data = event.data;
        try {
          const ev = JSON.parse(data);
          // if the user clicks on "add to pillar strategy" we want to post the pillar_topic_id and topic to neo
          if (ev.type === "drillmenu:click" && ev.label === "Add to Strategy") {
            const urlParams = new URLSearchParams(ev.url);
            const q = urlParams.get("q");

            const windowUrlParams = new URLSearchParams(window.location.search);
            const decodedPillarTopic = windowUrlParams.get("PillarTopic") || "";
            // decode the query string
            const decodedQ = decodeURIComponent(q || "");
            // if the url has a query string with type = blog
            if (ev.url.includes("type=blog")) {
              // send the pid, pillar_topic, decodedQ, and type to neo
              addBlogToPillarStrategy(pid, decodedPillarTopic, decodedQ);
            } else if (
              ev.url.includes("type=subpillar") &&
              decodedPillarTopic.length > 0 &&
              decodedQ.length > 0
            ) {
              // tell neo to add the subpillar to the pillar strategy
              addSubPillarToPillarStrategy(pid, decodedPillarTopic, decodedQ);
            } else {
              // failed to add to pillar strategy
              setShowAlertBanner(true, {
                message: `Failed to add to pillar strategy, please try again. If problem persists please contact customer success.`,
                severity: "error"
              });
            }
          }
          if (ev.dashboard && ev.type === "dashboard:tile:start") {
            const blocklistFilter =
              ev.dashboard.dashboard_filters["Blocklist Filter"];
            if (blocklistFilter) {
              setBlocklist("");
              addOrUpdateUrlParam("BlocklistFilter", blocklistFilter);
            }
            const lookerPillarTopic =
              ev.dashboard.dashboard_filters["Pillar Topic"];
            if (lookerPillarTopic === "" && allPillarTopics.length > 1 && pid) {
              addOrUpdateUrlParam("PillarTopic", "");
            }
            if (lookerPillarTopic) {
              const formattedLookerPillarTopic = lookerPillarTopic.split(",");
              if (
                formattedLookerPillarTopic &&
                allPillarTopics.length > 1 &&
                pid === parseInt(cookies.get("pid"))
              ) {
                formattedLookerPillarTopic.map((item: string) => {
                  if (
                    !allPillarTopics.includes(item.toLowerCase().trim()) &&
                    !notIncludedPillarArray.includes(item.toLowerCase().trim())
                  ) {
                    notIncludedPillarArray.push(` ${item}`);
                    if (notIncludedPillarArray.length === 1) {
                      setShowAlertBanner(true, {
                        message: `${notIncludedPillarArray} pillar is not active. Please select an active topic to see the most recent data.`,
                        severity: "warning"
                      });
                    }
                    if (notIncludedPillarArray.length > 1) {
                      setShowAlertBanner(true, {
                        message: `${notIncludedPillarArray} pillars are not active. Please select active topics to see the most recent data.`,
                        severity: "warning"
                      });
                    }
                  }
                });
                setAllPillarTopics([""]);
                notIncludedPillarArray = [];

                if (formattedLookerPillarTopic && allPillarTopics !== null) {
                  setPillarTopic(lookerPillarTopic);
                  addOrUpdateUrlParam(
                    "PillarTopic",
                    lookerPillarTopic.replace(/ /gu, "+")
                  );
                }
              }
            }
            const lookerTopic = ev.dashboard.dashboard_filters.Topic.replace(
              /_/u,
              " "
            );
            if (lookerTopic === "" && allInsights.length > 1 && pid) {
              addOrUpdateUrlParam("Topic", "");
            }
            if (lookerTopic.length > 1 && allInsights.length > 1 && pid) {
              if (allInsights.includes(lookerTopic.toLowerCase())) {
                setTopic(lookerTopic);
                addOrUpdateUrlParam("Topic", lookerTopic.replace(/ /gu, "+"));
              }
            }
            // if for some reason someone wanted to bookmark no topic or pillar topic, it wouldnt work because when opening a bookmark it is the "initial load".
            // if looker topic and pillar topic are true but they are not active aka included in the array of insights or pillar topics, set a warning message
            if (lookerTopic.length > 1 && allInsights.length > 1) {
              const formattedlLookerTopic = lookerTopic.split(",");
              if (
                formattedlLookerTopic &&
                allInsights.length > 1 &&
                pid === parseInt(cookies.get("pid"))
              ) {
                formattedlLookerTopic.map((item: string) => {
                  const updatedItem = item
                    .replace(/_/u, " ")
                    .toLowerCase()
                    .trim();
                  if (
                    !allInsights.includes(updatedItem) &&
                    !notIncludedInsights.includes(updatedItem)
                  ) {
                    notIncludedInsights.push(` ${item}`);
                    if (notIncludedInsights.length === 1) {
                      setShowAlertBanner(true, {
                        message: `${notIncludedInsights} insight is not active. Please select an active topic to see the most recent data.`,
                        severity: "warning"
                      });
                    }
                    if (notIncludedInsights.length > 1) {
                      setShowAlertBanner(true, {
                        message: `${notIncludedInsights} insights are not active. Please select active topics to see the most recent data.`,
                        severity: "warning"
                      });
                    }
                  }
                });
                setAllInsights([""]);
                notIncludedInsights = [];

                if (formattedlLookerTopic && allInsights !== null) {
                  setTopic(lookerTopic);
                  addOrUpdateUrlParam(
                    "Topic",
                    lookerTopic.replace(/_/u, " ").replace(/ /gu, "+")
                  );
                }
              }
            }
          }
        } catch {
          console.error("Error parsing JSON data:", Error);
        }
      }
    });
  }

  useEffect(() => {
    if (user.dj_employee || allowedUrls.includes(window.location.pathname)) {
      fetchData();
    } else if (
      user &&
      !isEmpty(user) &&
      !user.dj_employee &&
      allowedUrls.length > 0 &&
      !allowedUrls.includes(window.location.pathname)
    ) {
      navigate("/unauthorized");
      setShowAlertBanner(true, {
        message: `Unauthorized, please try again. If problem persists please contact customer success.`,
        severity: "error"
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, allowedUrls, user]);

  // This useffect is necessary so that when a user opens a bookmark, often the pid is set to undefined for a moment and it triggers fetchData,
  // which we do not want to do because it will reset the topic or pillar topic in the url thus rendering bookmarks useless
  useEffect(() => {
    cookies.set("pid", pid);
    setAllInsights([""]);
    const formattedTopic = topic.trim();
    if (
      pid &&
      allInsights.length > 1 &&
      !allInsights.includes(formattedTopic)
    ) {
      setTopic("");
      addOrUpdateUrlParam("Topic", "");
      fetchData();
    }

    setAllPillarTopics([""]);
    if (pid && pillarTopicFromUrl === "") {
      fetchData();
      if (!allPillarTopics.includes(pillarTopic)) {
        setPillarTopic("");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pid && topic]);

  useEffect(() => {
    if (iframe && pid && !handleMessageSetUp) {
      handleMessage();
      setHandleMessageSetUp(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iframe]);

  // set cookies and update the url when topics or pillartopics change
  useEffect(() => {
    if (topic?.length) {
      const formattedTopic = topic
        .replace(/_/u, " ")
        .replace(/ /gu, "+")
        .toLowerCase();
      addOrUpdateUrlParam("Topic", formattedTopic);
      cookies.set("Topic", formattedTopic, { path: "/" });
    }
    if (pillarTopic?.length) {
      const formattedPillarTopic = pillarTopic
        .trim()
        .replace(/ /gu, "+")
        .toLowerCase();
      addOrUpdateUrlParam("PillarTopic", formattedPillarTopic);
      cookies.set("PillarTopic", formattedPillarTopic, {
        path: "/"
      });
    }
  }, [topic, pillarTopic]);

  useEffect(() => {
    if (blockListFromUrl === null) {
      setBlocklist("");
      addOrUpdateUrlParam("BlocklistFilter", "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  return (
    <div className="App">
      {!loading && URL && (
        <iframe
          src={URL}
          id="looker"
          className="dashboard-container"
          style={{
            width: getUsableWidth(sideMenuOpen, isMobile)
          }}
        ></iframe>
      )}
    </div>
  );
}
export default Dashboard;
