import React, { memo } from "react";

import DataOrderSubTitle from "@/components/topic_authority/components/DataOrder/DataOrderSubTitle";
import DataOrderRow from "@/components/topic_authority/components/DataOrder/DataOrderRow";
import DataNotFound from "@/components/topic_authority/components/DataOrder/DataNotFound";
import {
  DataOrderType,
  IDataNetworkItem,
  IDataOrder,
  IDataPriorityItem,
  IDataRankingItem
} from "@/interfaces/topic-authority.interface";

import styles from "./DataOrder.module.scss";

interface IDataOrderProps {
  data?: IDataOrder<IDataRankingItem | IDataPriorityItem | IDataNetworkItem>;
  type: DataOrderType;
}

function DataOrder({ data, type }: IDataOrderProps) {
  return (
    <div className={styles.mainContainer}>
      {data ? (
        <>
          {data.increase && (
            <>
              <DataOrderSubTitle type={type} changeType="increase" />
              {data.increase.map((value, index) => (
                <DataOrderRow item={value} type={type} key={index} />
              ))}
            </>
          )}
          {data.decrease && (
            <>
              <DataOrderSubTitle type={type} changeType="decrease" />
              {data.decrease.map((value, index) => (
                <DataOrderRow item={value} type={type} key={index} />
              ))}
            </>
          )}
        </>
      ) : (
        <DataNotFound />
      )}
    </div>
  );
}

export default memo(DataOrder);
