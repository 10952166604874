import React, { memo } from "react";

import { DataOrderType } from "@/interfaces/topic-authority.interface";

import styles from "./DataOrder.module.scss";

interface IDataOrderSubTitleProps {
  type: DataOrderType;
  changeType: "increase" | "decrease";
}

const subTitles = {
  ranking: {
    increase: "Position Increase",
    decrease: "Position Decrease"
  },
  priority: {
    increase: "Priority Increase",
    decrease: "Priority Decrease"
  },
  network: {
    increase: "New Terms Discovered",
    decrease: "Old Terms Removed"
  }
};

function DataOrderSubTitle({ type, changeType }: IDataOrderSubTitleProps) {
  return <div className={styles.subTitle}>{subTitles[type][changeType]}</div>;
}

export default memo(DataOrderSubTitle);
