/* eslint-disable max-lines */
/* eslint-disable no-confusing-arrow */

import { Divider, Grid, Paper } from "@mui/material";
import { useEffect, useState } from "react";
import LoadingSkeletons from "./LoadingSkeletons";
import Status from "../../insights/components/Status";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeywordsTable from "./KeywordsTable";
import { useAppStore, useCompetitorsStore, useEffectAsync } from "@/hooks";
import useInsightsStore from "@/hooks/useInsightsStore";
import {
  IInsights,
  IInsightsStore,
  IErrorInsightArray
} from "@/interfaces/insights.interface";
import { IAppStore } from "@/interfaces/app-store.interface";
import { getPaginatedInsights, getCompetitorsByPid } from "@/api/get";
import { computeDaysBetween } from "../../insights/utilities/date";
import InsightQuery from "../../insights/components/InsightQuery";
import { updateUnfinishedInsights } from "@/api/post";
import { Link } from "react-router-dom";
import { ICompetitorsStore } from "@/interfaces/competitors-store.interface";
import { ICompetitorsItem } from "@/interfaces/competitors.interface";
import NoCompetitorsComponent from "@/components/insights/components/NoCompetitorsComponent";
import isEmpty from "lodash/isEmpty";

interface IProps {
  errorArr: IErrorInsightArray[];
}

export default function TopicInsightsCard({ errorArr }: IProps) {
  const [insightsData, setInsightsData] = useState<IInsights[]>([]);
  const [isFreeUser, setIsFreeUser] = useState(false);
  const [open, setOpen] = useState(-1);
  const [loading, setLoading] = useState(true);
  const limit = 30;
  const workingStatuses = ["pending", "working"];
  const errorStatuses = [
    "ERROR-RES",
    "ERROR-ETL",
    "ERROR-PRE",
    "ERROR-STALE",
    "ERROR-DUP",
    "ERROR-ERR"
  ];
  const flexRowAC = {
    display: "flex",
    flexFlow: "row nowrap",
    alignItems: "center"
  };
  const columnLabel = {
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "20px",
    letterSpacing: "0.0015em"
  };

  const insightIncomplete = {
    fontSize: "16px",
    lineHeight: "20px",
    letterSpacing: "0.0015em"
  };
  const insightError = {
    fontSize: "16px",
    lineHeight: "20px",
    letterSpacing: "0.0015em",
    color: "#B00020"
  };
  const { selectedPidObject, selectedSubscriptionObject } = useAppStore(
    (store: IAppStore) => ({
      selectedPidObject: store.selectedPidObject,
      selectedSubscriptionObject: store.selectedSubscriptionObject
    })
  );
  // fetch competitors from store
  const { competitors, setCompetitors } = useCompetitorsStore(
    (store: ICompetitorsStore) => ({
      competitors: store.competitors,
      setCompetitors: (value: ICompetitorsItem[]) => store.setCompetitors(value)
    })
  );

  const {
    insights,
    pendingInsights,
    unfinishedInsights,
    insightPid,
    setInsights,
    setInsightHasMore,
    setInsightPid,
    setInsightPage,
    setUnfinishedInsights
  } = useInsightsStore((store: IInsightsStore) => ({
    insights: store.insights,
    pendingInsights: store.pendingInsights,
    unfinishedInsights: store.unfinishedInsights,
    insightPid: store.insightPid,
    setInsights: (value: IInsights[]) => store.setInsights(value),
    setInsightPid: (value: number) => store.setInsightPid(value),
    setInsightPage: (value: number) => store.setInsightPage(value),
    setInsightHasMore: (value: boolean) => store.setInsightHasMore(value),
    setUnfinishedInsights: (value: number[]) =>
      store.setUnfinishedInsights(value)
  }));
  useEffect(() => {
    const fetchInsights = async () => {
      let data: IInsights[];

      if (
        selectedPidObject.id === insightPid &&
        (insights.length > 0 || pendingInsights.length > 0)
      ) {
        setInsightsData(
          [...errorArr, ...pendingInsights, ...insights]
            .sort(
              (insightA, insightB) =>
                Number(insightB.created_at) - Number(insightA.created_at)
            )
            .slice(0, 5)
        );
      } else {
        if (selectedPidObject.id !== insightPid) {
          setInsightPid(selectedPidObject.id);
        }
        setInsightPage(1);
        setInsights([]);
        setInsightsData([]);
        data = await getPaginatedInsights(selectedPidObject.id, 1, limit);
        if (data?.length) {
          setInsights(data);
          setInsightPage(2);
          setInsightsData(
            [...errorArr, ...pendingInsights, ...data]
              .sort(
                (insightA, insightB) =>
                  Number(insightB.created_at) - Number(insightA.created_at)
              )
              .slice(0, 5)
          );
          if (data.length < 10) {
            setInsightHasMore(false);
          }
        } else {
          setInsightHasMore(false);
        }
        const fetchedUnfinishedInsights: number[] = [];
        for (let i = 0; i < data.length; i++) {
          const currInsight = data[i];
          if (
            computeDaysBetween(new Date(), new Date(currInsight.created_at)) > 1
          ) {
            break;
          }
          if (
            workingStatuses.includes(currInsight.status) &&
            currInsight.id &&
            !fetchedUnfinishedInsights.includes(currInsight.id)
          ) {
            fetchedUnfinishedInsights.push(currInsight.id);
          }
        }
        setUnfinishedInsights(fetchedUnfinishedInsights);
      }
      setLoading(false);
    };
    if (selectedPidObject.id) {
      fetchInsights();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPidObject, pendingInsights, insights?.length, errorArr]);

  useEffectAsync(async () => {
    if (selectedPidObject.id) {
      setLoading(true);
      const fetchedCompetitors = await getCompetitorsByPid(
        selectedPidObject.id
      );
      setCompetitors(fetchedCompetitors);
      setLoading(false);
    }
  }, [selectedPidObject]);

  useEffect(() => {
    setIsFreeUser(
      selectedSubscriptionObject?.cb_plan_id?.includes("free") ||
        isEmpty(selectedSubscriptionObject)
    );
  }, [selectedSubscriptionObject]);

  useEffect(() => {
    const checkUnfinished = setTimeout(async () => {
      if (unfinishedInsights.length) {
        const localInsightIds = unfinishedInsights;
        try {
          const response = await updateUnfinishedInsights(
            selectedPidObject.id,
            unfinishedInsights
          );
          const updatedInsights = response.data;
          setInsights(
            insights.map((insight: IInsights) => {
              if (localInsightIds.includes(insight.id)) {
                const foundUpdatedInsight = updatedInsights.find(
                  (uInsight: IInsights) => uInsight.id === insight.id
                );
                if (foundUpdatedInsight) {
                  return foundUpdatedInsight;
                }
              }
              return insight;
            })
          );
          const stillUnfinished = updatedInsights
            .filter((uInsight: IInsights) =>
              workingStatuses.includes(uInsight.status)
            )
            .map((uInsight: IInsights) => uInsight.id);
          setUnfinishedInsights(stillUnfinished);
        } catch (error) {
          console.error(error);
        }
      }
    }, 5000);
    return () => clearTimeout(checkUnfinished);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unfinishedInsights]);

  const handleChange = (index: number) => {
    if (index === open) {
      setOpen(-1);
    } else {
      setOpen(index);
    }
  };

  return (
    <div id="topic-insights-card">
      <Paper id="paper-gray-border" elevation={0}>
        <div
          style={{
            fontWeight: "600",
            fontSize: "24px",
            lineHeight: "30px",
            letterSpacing: "0.0015em",
            padding: "20px"
          }}
        >
          Generated Topic Insights
        </div>
        {!loading && competitors.length !== 0 ? (
          <div
            style={{
              ...flexRowAC,
              padding: "20px"
            }}
          >
            <Grid container>
              <Grid item className="w-75">
                <div style={columnLabel}>Topic</div>
              </Grid>
              <Grid item className="w-25">
                <div style={columnLabel}>Status</div>
              </Grid>
            </Grid>
          </div>
        ) : null}
        <Divider />
        <div id="insights-table">
          {/* The below code accounts for streaming in the future. For now i set status to
          streaming because it will always resolve to the falsey and just show the non accodion
          section */}

          {loading ? (
            <LoadingSkeletons />
          ) : (
            <>
              {isEmpty(competitors) ? (
                <NoCompetitorsComponent page="Insights" />
              ) : (
                <>
                  {insightsData.map((insight, index) =>
                    insight.status === "streaming" ? (
                      <div
                        key={`${index}-stream-${insight.id}`}
                        className="myrow accordion-row"
                      >
                        <Grid
                          data-testid="insight-row"
                          container
                          sx={{ alignItems: "center" }}
                        >
                          <Accordion
                            expanded={index === open}
                            onChange={() => {
                              handleChange(index);
                            }}
                            style={{ width: "100%" }}
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <Grid item className="w-75">
                                <div
                                  className="d-flex align-items-center h-100"
                                  style={insightIncomplete}
                                >
                                  <InsightQuery
                                    query={insight.query_string}
                                    length={80}
                                  ></InsightQuery>
                                </div>
                              </Grid>
                              <Grid
                                item
                                className="w-25"
                                style={{
                                  paddingLeft: "10px"
                                }}
                              >
                                <Status
                                  insight={insight}
                                  status={
                                    workingStatuses.includes(insight.status) &&
                                    computeDaysBetween(
                                      new Date(),
                                      new Date(insight.created_at)
                                    ) > 1
                                      ? "ERROR-STALE"
                                      : insight.status
                                  }
                                  percentComplete={insight.percent_complete}
                                  daysSince={computeDaysBetween(
                                    new Date(),
                                    new Date(insight.created_at)
                                  )}
                                />
                              </Grid>
                            </AccordionSummary>
                            <AccordionDetails>
                              <KeywordsTable />
                            </AccordionDetails>
                          </Accordion>
                        </Grid>
                      </div>
                    ) : (
                      <div
                        key={`${index}-non-stream-${insight.id}`}
                        className="myrow"
                      >
                        <Grid
                          container
                          sx={{
                            alignItems: "center"
                          }}
                        >
                          <Grid item className="w-75">
                            {insight.status === "complete" ? (
                              <Link
                                to={`/dashboard/topic-summary${
                                  isFreeUser ? "-free" : ""
                                }?&Topic=${insight.query_string.replace(
                                  / /u,
                                  "_"
                                )}`}
                                style={{
                                  ...insightIncomplete,
                                  textDecorationLine: "underline",
                                  color: "#008CCE"
                                }}
                              >
                                <InsightQuery
                                  query={insight.query_string}
                                  length={80}
                                ></InsightQuery>
                              </Link>
                            ) : (
                              <div
                                style={
                                  errorStatuses.includes(insight.status)
                                    ? insightError
                                    : insightIncomplete
                                }
                              >
                                <InsightQuery
                                  query={insight.query_string}
                                  length={80}
                                ></InsightQuery>
                              </div>
                            )}
                          </Grid>
                          <Grid item className="w-25">
                            <Status
                              insight={insight}
                              status={
                                workingStatuses.includes(insight.status) &&
                                computeDaysBetween(
                                  new Date(),
                                  new Date(insight.created_at)
                                ) > 1
                                  ? "ERROR-STALE"
                                  : insight.status
                              }
                              percentComplete={insight.percent_complete}
                              daysSince={computeDaysBetween(
                                new Date(),
                                new Date(insight.created_at)
                              )}
                            />
                          </Grid>
                        </Grid>
                      </div>
                    )
                  )}
                </>
              )}
            </>
          )}
        </div>
      </Paper>
    </div>
  );
}
