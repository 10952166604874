/* eslint-disable max-lines */
import { Dispatch, SetStateAction, useState, useEffect, useMemo } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import debounce from "lodash/debounce";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useAppStore } from "../../../../hooks";
import { ActionSearch, IconPrimaryDomain } from "@/assets/icons/svgr";
import DJForm from "../../../../components/shared/DJForm";
import DJTextField from "../../../../components/shared/DJTextField";
import {
  getUserAccountsAndPidsPaginated,
  getUserAccountsAndPidsBySearchTerm
} from "../../../../api/get";
import { IPDomain } from "../../../../interfaces/pdomain.interface";
import { IAccount } from "../../../../interfaces/account.interface";
import { IAppStore } from "../../../../interfaces/app-store.interface";
import LoadingElement from "./LoadingElement";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { addOrUpdateUrlParam } from "../../../../routines/addOrUpdateUrl";
import { useSearchParams } from "react-router-dom";
import Cookies from "universal-cookie";

const SelectorBox = ({
  setPidSelectorMenuOpen,
  totalPages
}: {
  setPidSelectorMenuOpen: Dispatch<SetStateAction<boolean>>;
  totalPages: number;
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const cookies = new Cookies();
  const {
    setSelectedPidObject,
    setSelectedAccountObject,
    setUserAccounts,
    userAccounts
  } = useAppStore((store: IAppStore) => ({
    selectedPidObject: store.selectedPidObject,
    selectedAccountObject: store.selectedAccountObject,
    userAccounts: store.userAccounts,
    setSelectedPidObject: (value: IPDomain) =>
      store.setSelectedPidObject(value),
    setUserAccounts: (value: IAccount[]) => store.setUserAccounts(value),
    setSelectedAccountObject: (value: IAccount) =>
      // eslint-disable-next-line implicit-arrow-linebreak
      store.setSelectedAccountObject(value)
  }));
  // the page number of the currentPage
  const [currentPage, setCurrentPage] = useState(1);
  // this is boolean for if there are more items the infinite scroll can display after the current items
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  // these are the set of current displayed 10 items
  const [current, setCurrent] = useState<IAccount[]>([]);
  // this runs on page load because that's the only time userAccounts is added to??
  useEffect(() => {
    if (userAccounts?.length) {
      setCurrent(userAccounts.slice(0, 10));
      if (userAccounts.length > 10) {
        setHasMore(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // this runs after a debounce when a user inputs anything into the search box
  const handleSearch = async (searchTerm: string) => {
    // if the user has entered a search term, just fetch the whole data set
    setHasMore(false);
    setCurrentPage(1);
    if (searchTerm === "" || searchTerm.length < 3) {
      setLoading(false);
      setCurrent(userAccounts.slice(0, 10));
      if (totalPages > 1) {
        setHasMore(true);
      }
      return;
    }
    setLoading(true);
    // search term should get url encoded TODO
    const searchResults = await getUserAccountsAndPidsBySearchTerm(
      encodeURIComponent(searchTerm)
    );
    setCurrent(searchResults);
    setLoading(false);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedHandleSearch = useMemo(() => debounce(handleSearch, 300), []);

  const getMoreData = async () => {
    if (currentPage >= totalPages) {
      setHasMore(false);
    } else {
      setHasMore(true);
      const response = await getUserAccountsAndPidsPaginated(
        currentPage + 1,
        10
      );
      const additionalData = response.data;
      setCurrent(current.concat(additionalData));
      setCurrentPage(currentPage + 1);
    }
  };

  const renderAccountsAndPids = () => (
    <div>
      {loading ? (
        <div className="d-flex justify-content-center w-100 mt-3">
          <div className="spinner-grow" role="status">
            <span className="sr-only">Loading...</span>
          </div>
          <div className="spinner-grow" role="status">
            <span className="sr-only">Loading...</span>
          </div>
          <div className="spinner-grow" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
        <List sx={{ width: "100%" }}>
          <InfiniteScroll
            dataLength={current.length}
            next={getMoreData}
            hasMore={hasMore}
            loader={<LoadingElement />}
            scrollableTarget="scrollableAccounts"
          >
            {current.map((account) => (
              <div key={`account-${account.id}`}>
                <ListItem style={{ padding: 0, width: "100%" }}>
                  <div
                    style={{
                      paddingLeft: "30px",
                      paddingTop: "7px",
                      paddingBottom: "7px",
                      fontFamily: "'Source Sans Pro'",
                      fontStyle: "normal",
                      fontWeight: "600",
                      fontSize: "14px",
                      lineHeight: "18px",
                      letterSpacing: "0.001em",
                      color: "#373F50"
                    }}
                  >
                    {account.company_name}
                  </div>
                </ListItem>
                {account.pdomains.map((pid) => (
                  <ListItem
                    sx={{
                      "&:hover": {
                        backgroundColor: "#E1F5FF"
                      }
                    }}
                    style={{ padding: 0 }}
                    key={`pid-${pid.id}`}
                  >
                    <a
                      style={{
                        paddingLeft: "30px",
                        paddingTop: "7px",
                        paddingBottom: "7px",
                        fontFamily: "'Source Sans Pro'",
                        fontStyle: "normal",
                        fontWeight: "600",
                        fontSize: "14px",
                        lineHeight: "18px",
                        letterSpacing: "0.0125em",
                        textDecorationLine: "underline",
                        color: "#008CCE"
                      }}
                      onClick={() => {
                        if (searchParams.has("BlocklistFilter")) {
                          searchParams.delete("BlocklistFilter");
                        }
                        if (searchParams.has("Topic")) {
                          searchParams.delete("Topic");
                        }
                        if (searchParams.has("PillarTopic")) {
                          searchParams.delete("PillarTopic");
                        }
                        cookies.remove("Topic", { path: "/" });
                        cookies.remove("PillarTopic", { path: "/" });
                        cookies.remove("BlockList", { path: "/" });
                        setSearchParams(searchParams);
                        addOrUpdateUrlParam("pid", pid.id.toString());
                        setSelectedAccountObject(account);
                        setSelectedPidObject(pid);
                        setUserAccounts(userAccounts);
                        setPidSelectorMenuOpen(false);
                      }}
                    >
                      <span>
                        {pid.display_name}
                        {pid?.display_name?.length ? " - " : ""} {`${pid.id}`}
                      </span>
                    </a>
                  </ListItem>
                ))}
              </div>
            ))}
          </InfiniteScroll>
        </List>
      )}
    </div>
  );
  return (
    <ClickAwayListener onClickAway={() => setPidSelectorMenuOpen(false)}>
      <Grid
        container
        sx={{
          boxShadow: 2,
          color: "darkgray",
          boxSizing: "border-box",
          position: "absolute",
          width: "266px",
          height: "329px",
          right: "30px",
          top: "100%",
          background: "#FFFFFF",
          border: "1px solid #E9EBF0",
          borderRadius: "8px"
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            padding: "0",
            marginTop: "20px"
          }}
        >
          <IconPrimaryDomain
            style={{
              height: "29px",
              width: "24px",
              marginLeft: "15px",
              marginRight: "10px",
              color: "#373F50"
            }}
          />
          <Box sx={{ flexDirection: "column" }}>
            <h5
              style={{
                fontFamily: "'Source Sans Pro'",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "20px",
                textAlign: "left",
                letterSpacing: "-0.015em",
                color: "#373F50",
                margin: "0 0 0 0"
              }}
            >
              Primary Domain
            </h5>
            <h6
              style={{
                fontFamily: "'Source Sans Pro'",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "12px",
                lineHeight: "15px",
                textAlign: "left",
                letterSpacing: "0.004em",
                color: "#56627C",
                margin: "0 0 0 0 "
              }}
            >
              Switch to a new account or domain
            </h6>
          </Box>
        </Box>
        <Box
          sx={{
            marginRight: "5px",
            width: "100%",
            marginLeft: "5px"
          }}
        >
          <DJForm
            initialValues={{ searchTerm: "" }}
            onSubmit={() => {
              // do nothing
            }}
          >
            <DJTextField
              type="text"
              name="searchTerm"
              label="Search"
              iconBefore={
                <ActionSearch style={{ width: "24px", color: "#373F50" }} />
              }
              onChange={(event: { target: { value: string } }) => {
                setLoading(true);
                debouncedHandleSearch(event.target.value);
              }}
            />
          </DJForm>
        </Box>
        <div
          id="scrollableAccounts"
          style={{
            height: "202px",
            overflowY: "scroll",
            width: "100%"
          }}
        >
          {current.length ? (
            renderAccountsAndPids()
          ) : (
            <div
              style={{
                paddingLeft: "30px",
                paddingTop: "12px",
                paddingBottom: "7px",
                fontFamily: "'Source Sans Pro'",
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "14px",
                lineHeight: "18px",
                letterSpacing: "0.001em",
                color: "#373F50"
              }}
            >
              No results
            </div>
          )}
        </div>
      </Grid>
    </ClickAwayListener>
  );
};
export default SelectorBox;
