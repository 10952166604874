import PropTypes from "prop-types";
import { useLocalStore } from "mobx-react-lite";
import StoreContext from "./StoreContext";
import createAppStore from "./appStore";
import createInsightsStore from "./insightsStore";
import createManageUsersStore from "./manageUsersStore";
import createCompetitorsStore from "./competitorsStore";
import createPillarStrategyStore from "./pillarStrategyStore";
import createContentBriefsStore from "./contentBriefsStore";
import createTopicAuthorityStore from "./topicAuthorityStore";
import createDemandaAIStore from "./demandaAIStore";

const StoreProvider = ({ children }) => {
  const appStore = useLocalStore(createAppStore);
  const insightsStore = useLocalStore(createInsightsStore);
  const manageUsersStore = useLocalStore(createManageUsersStore);
  const competitorsStore = useLocalStore(createCompetitorsStore);
  const pillarStrategyStore = useLocalStore(createPillarStrategyStore);
  const contentBriefsStore = useLocalStore(createContentBriefsStore);
  const contentTopicAuthorityStore = useLocalStore(createTopicAuthorityStore);
  const demandaAIStore = useLocalStore(createDemandaAIStore);

  return (
    <StoreContext.Provider
      value={{
        appStore,
        insightsStore,
        manageUsersStore,
        competitorsStore,
        pillarStrategyStore,
        contentBriefsStore,
        contentTopicAuthorityStore,
        demandaAIStore
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};

StoreProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)
  ])
};

export default StoreProvider;
