import { useState, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import InfiniteScroll from "react-infinite-scroll-component";
import Collapsible from "react-collapsible";
import "./backoffice.scss";
import isEmpty from "lodash/isEmpty";

import AccountForm from "./components/AccountForm";
import PdomainForm from "./components/PdomainForm";

import { useAppStore } from "../../../hooks";
import { getAccountPdomain, cronRun } from "../../../api/get";
import LoadingElement from "../../navigation/components/PidSelector/LoadingElement";
import DJLoadingGif from "../../shared/DJLoadingGif";
import { useNavigate } from "react-router-dom";

const rowStyle = {
  width: "100%",
  padding: "10px",
  border: "1px solid lightgrey",
  marginBottom: "5px"
};
const inputStyle = { width: "200px", marginLeft: "15px" };

function Backoffice() {
  const { user, setShowAlertBanner } = useAppStore((store) => ({
    user: store.user,
    setShowAlertBanner: store.setShowAlertBanner
  }));
  const [loading, setLoading] = useState(true);
  const [allItems, setAllItems] = useState();
  const [company, setCompany] = useState("");
  const [newPid, setNewPid] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [count, setCount] = useState({
    next: 10,
    prev: 0
  });
  const [currentFilteredItems, setCurrentFilteredItems] = useState();

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const accountPdomains = await getAccountPdomain().catch((err) => {
        console.error(err);
        return null;
      });
      if (accountPdomains) {
        setAllItems(accountPdomains.accounts);
        setCurrentFilteredItems(accountPdomains.accounts.slice(0, 10));
        setHasMore(true);
      } else {
        setAllItems([]);
      }
      setLoading(false);
    };
    if (loading) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (user && !isEmpty(user) && !user.dj_employee) {
      navigate("/unauthorized");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const getMoreData = () => {
    if (currentFilteredItems.length === allItems.length) {
      setHasMore(false);
      return;
    }
    setCurrentFilteredItems(
      currentFilteredItems.concat(
        allItems.slice(count.prev + 10, count.next + 10)
      )
    );
    setCount((prevState) => ({
      next: prevState.next + 10,
      prev: prevState.prev + 10
    }));
  };

  const pdomainList = () =>
    currentFilteredItems
      .filter((r) =>
        r.company_name.toLowerCase().includes(company.toLowerCase())
      )
      .map((row) => (
        <div key={row.id} style={{ width: "100%" }}>
          {!loading && (
            <div
              style={{
                width: "100%",
                borderBottom: "1px solid lightgrey",
                padding: "10px",
                marginBottom: "10px",
                marginTop: "10px"
              }}
            >
              <div style={rowStyle}>
                <Collapsible
                  trigger={
                    <div>
                      -{" "}
                      <span style={{ fontSize: "large" }}>
                        {row.company_name}
                      </span>
                    </div>
                  }
                >
                  <div
                    style={{
                      borderTop: "1px solid lightgrey",
                      paddingTop: "10px"
                    }}
                  >
                    {row.pdomains.map((pdomain) => (
                      <div
                        key={pdomain.id}
                        style={{
                          border: "1px solid grey",
                          padding: "10px"
                        }}
                      >
                        <h5>{pdomain.domain.name}</h5>
                        <span>
                          <b>Display Name:</b> {pdomain.display_name}
                        </span>
                        <br />
                        <span>
                          <b>Domain:</b> {pdomain.domain.name}
                        </span>
                        <br />
                        <span>
                          <b>PID:</b> {pdomain.id}
                        </span>
                      </div>
                    ))}
                    <PdomainForm
                      accountId={row.id}
                      allItems={allItems}
                      setAllItems={setAllItems}
                      currentFilteredItems={currentFilteredItems}
                      setCurrentFilteredItems={setCurrentFilteredItems}
                      newPid={newPid}
                      setNewPid={setNewPid}
                    />
                  </div>
                </Collapsible>
              </div>
            </div>
          )}
        </div>
      ));

  const handleChange = (e) => {
    e.preventDefault();
    setLoading(true);
    setCompany(e.target.value);
    if (e.target.value.length > 1) {
      setTimeout(() => {
        const item = allItems.filter((r) =>
          r.company_name.toLowerCase().includes(e.target.value.toLowerCase())
        );
        setCurrentFilteredItems(item);
        setLoading(false);
      }, 800);
    }
    if (e.target.value.length <= 1) {
      setCurrentFilteredItems(allItems.slice(0, 10));
      setLoading(false);
    }
  };

  const handleCron = async () => {
    await cronRun();
    if (cronRun) {
      setShowAlertBanner(true, {
        message: "Cron has successfully run",
        severity: "success"
      });
    }
  };
  return (
    <>
      {user?.dj_employee ? (
        <div>
          {allItems && allItems.length > 0 && (
            <>
              <div
                style={{
                  backgroundColor: "rgba(55, 63, 80, 0.1)",
                  padding: "20px"
                }}
              >
                <div style={{ fontSize: "30px" }}>Backoffice</div>
                <small style={{ opacity: ".6" }}>
                  Create new accounts and pdomains
                </small>
                <button
                  className="btn btn-success"
                  style={{ float: "right" }}
                  onClick={handleCron}
                >
                  CRON
                </button>
              </div>
              <Paper>
                <AccountForm
                  allItems={allItems}
                  setAllItems={setAllItems}
                  handleChange={handleChange}
                />
                <hr style={{ color: "gray" }} />
                <span style={{ padding: "20px" }}>Search by Account Name:</span>
                <input
                  style={inputStyle}
                  className="form-control"
                  type="text"
                  onChange={handleChange}
                  value={company}
                ></input>
                {loading && <DJLoadingGif />}
                {!loading && (
                  <div style={{ width: "100%" }}>
                    <div>
                      <div>
                        <div
                          id="scrollable-div"
                          style={{
                            height: "801px",
                            overflowY: "scroll",
                            width: "100%"
                          }}
                        >
                          <InfiniteScroll
                            dataLength={currentFilteredItems.length}
                            next={getMoreData}
                            hasMore={hasMore}
                            scrollThreshold={0.6}
                            scrollableTarget="scrollable-div"
                            loader={<LoadingElement />}
                          >
                            {pdomainList()}
                            {currentFilteredItems.length === 0 && (
                              <div
                                style={{
                                  padding: "20px"
                                }}
                              >
                                No account was found by that name
                              </div>
                            )}
                          </InfiniteScroll>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </Paper>
            </>
          )}
        </div>
      ) : (
        <div>Must be a DemandJump Employee to view this page</div>
      )}
    </>
  );
}
export default Backoffice;
