import { StoreContext } from "../stores";
import useStore from "./useStore";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useTopicAuthorityStore = (dataSelector: any) =>
  useStore(
    StoreContext,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (contextData: any = {}) => contextData.contentTopicAuthorityStore,
    dataSelector
  );

export default useTopicAuthorityStore;
