import * as React from "react";
import { Modal, Spinner } from "react-bootstrap";
import IconCheckmark from "@/assets/icons/icon-checkmark.svg";
// A modal 300 px high and 400 px wide with a close button in the top right corner
// everything behind the modal is not clickable
// the modal has a 120px x 120px spinner centered in the middle of the heading that spins while fetchingNewTopic is true
// once fetchingNewTopic is false, the spinner is replaced with a large checkmark icon
// the modal has a heading that sayd `Adding "${searchedTopic}" to strategy`
// the modal has a subheading that says `Please wait. This will only take a few seconds.`
// if fetchingNewTopic is false, the modal has a close "X" button in the top right corner

const LoadingNewlyAddedTopicModal = ({
  fetchingNewTopic,
  setFetchingNewTopic,
  topicWasFound,
  newTopic
}: {
  fetchingNewTopic: boolean;
  setFetchingNewTopic: React.Dispatch<React.SetStateAction<boolean>>;
  topicWasFound: boolean;
  newTopic: string;
}) => {
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  // when fetchingNewTopic flips to false, set a timer to close the modal after 3 seconds

  React.useEffect(() => {
    if (fetchingNewTopic) {
      setModalIsOpen(true);
    } else if (!fetchingNewTopic && modalIsOpen) {
      setTimeout(() => {
        setModalIsOpen(false);
      }, 3000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchingNewTopic, setFetchingNewTopic]);

  return (
    // give the react-bootstrap modal a width of 364px
    <Modal
      show={modalIsOpen}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={false}
      style={{ minWidth: "364px" }}
    >
      <Modal.Header style={{ border: "none" }}>
        {fetchingNewTopic && (
          <Spinner
            animation="border"
            role="status"
            style={{
              width: "90px",
              height: "90px",
              color: "#008cce",
              margin: "auto",
              position: "relative"
            }}
          >
            <span className="sr-only">Loading...</span>
          </Spinner>
        )}
        {!fetchingNewTopic && topicWasFound && (
          <img
            style={{
              width: "160px",
              height: "160px",
              margin: "auto",
              position: "relative"
            }}
            src={IconCheckmark}
            alt="process complete"
          />
        )}
        {!fetchingNewTopic && topicWasFound && (
          <button
            style={{
              position: "relative",
              marginLeft: "-15px",
              padding: "15px",
              marginTop: "-140px"
            }}
            className="btn btn-close"
            onClick={() => setModalIsOpen(false)}
          />
        )}
      </Modal.Header>

      <Modal.Title
        style={{
          textAlign: "center",
          fontSize: "24px",
          fontWeight: 400,
          fontFamily: "Source Sans Pro",
          lineHeight: "30px",
          letterSpacing: "0.0025em",
          padding: "30px"
        }}
      >
        {fetchingNewTopic && <>Adding "{newTopic}" to strategy</>}
        {!fetchingNewTopic && topicWasFound && (
          <>Successfully added to strategy</>
        )}
      </Modal.Title>
      <Modal.Body
        style={{
          textAlign: "center",
          fontSize: "16px",
          fontWeight: "initial"
        }}
      >
        {fetchingNewTopic ? (
          <p>Please wait. This will only take a few seconds.</p>
        ) : (
          // grey color text #6c757d
          <p style={{ color: "#6c757d" }}>
            This popup will close automatically.
          </p>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default LoadingNewlyAddedTopicModal;
