import { useEffect, useState } from "react";
import clsx from "clsx";
import { getTopicAuthorityData } from "@/api/get";
import { useAppStore, useTopicAuthorityStore } from "@/hooks";
import CircularProgress from "@mui/material/CircularProgress";
import { ITopicAuthority } from "@/interfaces/topic-authority.interface";
import TopicAuthorityScore from "@/components/topic_authority/components/TopicAuthorityScore";
import TopicAuthorityTrend from "@/components/topic_authority/components/TopicAuthorityTrend";
import TopicAuthorityData from "@/components/topic_authority/components/TopicAuthorityData";
import { IAppStore } from "@/interfaces/app-store.interface";
import { ITopicAuthorityStore } from "@/interfaces/topic-authority-store.interface";

import styles from "./TopicAuthorityContent.module.scss";

interface ITopicAuthorityContentProps {
  topic: ITopicAuthority;
}

function TopicAuthorityContent({ topic }: ITopicAuthorityContentProps) {
  const [loading, setLoading] = useState(true);

  const { selectedTopic, setSelectedTopic }: ITopicAuthorityStore =
    useTopicAuthorityStore((store: ITopicAuthorityStore) => ({
      selectedTopic: store.selectedTopic,
      setSelectedTopic: (topic: ITopicAuthority) =>
        store.setSelectedTopic(topic)
    }));

  const { selectedPidObject, setShowAlertBanner } = useAppStore(
    (store: IAppStore) => ({
      selectedPidObject: store.selectedPidObject,
      setShowAlertBanner: store.setShowAlertBanner
    })
  );

  useEffect(() => {
    const fetchData = async (topicId: number) => {
      setLoading(true);
      await getTopicAuthorityData({
        pid: selectedPidObject.id,
        id: topicId
      })
        .then((response) => {
          setSelectedTopic(response);
        })
        .catch((e) => {
          console.error("error:", e);
          setShowAlertBanner(true, {
            message: `Error fetching Topic Authority, please try again. If problem persists please contact customer success.`,
            severity: "error"
          });
        });

      setLoading(false);
    };

    if (selectedPidObject?.id && topic?.id) {
      fetchData(topic.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPidObject.id, setShowAlertBanner, topic.id]);

  return (
    <div className={styles.taContentContainer}>
      {loading ? (
        <div className={styles.taContentEmpty}>
          <CircularProgress />
        </div>
      ) : (
        <>
          <div className={clsx(styles.taContentParts, styles.taContentLeft)}>
            <TopicAuthorityScore topic={selectedTopic} />
            <TopicAuthorityTrend topic={selectedTopic} />
          </div>
          <div className={clsx(styles.taContentParts, styles.taContentRight)}>
            <TopicAuthorityData topic={selectedTopic} />
          </div>
        </>
      )}
    </div>
  );
}

export default TopicAuthorityContent;
