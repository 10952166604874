import { useCallback, useEffect, useMemo, useState } from "react";
import isEmpty from "lodash/isEmpty";
import { getTopicAuthorities } from "@/api/get";
import { useAppStore, useTopicAuthorityStore } from "@/hooks";
import { IAppStore } from "@/interfaces/app-store.interface";
import { ITopicAuthority } from "@/interfaces/topic-authority.interface";
import TopicAuthorityContent from "@/components/topic_authority/components/TopicAuthorityContent";
import DemandaAI from "@/components/shared/DemandaAI";
import Tabs from "@/components/shared/Tabs";
import TopicAuthorityContentEmpty from "@/components/topic_authority/components/TopicAuthorityContentEmpty";
import { ITopicAuthorityStore } from "@/interfaces/topic-authority-store.interface";

import styles from "./TopicAuthority.module.scss";
import { useMediaQuery } from "react-responsive";

const TopicAuthority = () => {
  const {
    selectedTopicTabIndex,
    selectedPidTopics,
    setSelectedPidTopics,
    setSelectedTopicTabIndex,
    setSelectedDataTabIndex
  }: ITopicAuthorityStore = useTopicAuthorityStore(
    (store: ITopicAuthorityStore) => ({
      selectedTopicTabIndex: store.selectedTopicTabIndex,
      selectedPidTopics: store.selectedPidTopics,
      setSelectedPidTopics: (value: ITopicAuthority[]) =>
        store.setSelectedPidTopics(value),
      setSelectedTopicTabIndex: (value: number) =>
        store.setSelectedTopicTabIndex(value),
      setSelectedDataTabIndex: (value: number) =>
        store.setSelectedDataTabIndex(value)
    })
  );
  const [loading, setLoading] = useState(true);
  const isMobile = useMediaQuery({ query: `(max-width: 1024px)` });

  const { selectedPidObject, setShowAlertBanner } = useAppStore(
    (store: IAppStore) => ({
      selectedPidObject: store.selectedPidObject,
      setShowAlertBanner: store.setShowAlertBanner
    })
  );

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await getTopicAuthorities(selectedPidObject.id)
        .then((response) => {
          setSelectedPidTopics(response);
        })
        .catch((e) => {
          console.error("error:", e);
          setShowAlertBanner(true, {
            message: `Error fetching Topic Authority, please try again. If problem persists please contact customer success.`,
            severity: "error"
          });
        });

      setLoading(false);
    };

    if (selectedPidObject?.id) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPidObject.id, setShowAlertBanner]);

  const tabItems = useMemo(
    () => selectedPidTopics.map((item) => ({ title: item.keyword })),
    [selectedPidTopics]
  );

  const handleTabClick = useCallback(
    (stopLoading: () => void, index: number) => {
      setSelectedDataTabIndex(0);
      setSelectedTopicTabIndex(index);
    },
    [setSelectedDataTabIndex, setSelectedTopicTabIndex]
  );

  return (
    <div className={styles.taContainer}>
      <div className={styles.topicAuthorityHeading}>
        <h1>Topic Authority</h1>
      </div>

      <div className={styles.tabsTableContainer}>
        {selectedPidTopics.length > 0 ? (
          <Tabs
            items={tabItems}
            onClick={handleTabClick}
            isDynamicSizes={isMobile}
            isStaticHeight={isMobile}
          />
        ) : (
          <div className={styles.spacer}></div>
        )}
        {!loading &&
        selectedPidTopics.length &&
        !isEmpty(selectedPidTopics[selectedTopicTabIndex]) ? (
          <TopicAuthorityContent
            topic={selectedPidTopics[selectedTopicTabIndex]}
          />
        ) : (
          <TopicAuthorityContentEmpty loading={loading} />
        )}
      </div>
      <DemandaAI active={false} />
    </div>
  );
};

export default TopicAuthority;
