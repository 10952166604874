import React, { memo, useMemo } from "react";
import DemandaLogo from "@/assets/icons/demanda-logo.svg";

import styles from "./DemandaAI.module.scss";
import clsx from "clsx";

interface IDemandaMessageProps {
  role: "assistant" | "user";
  text: string;
}

const DemandaMessage = ({ role, text }: IDemandaMessageProps) => {
  const isAssistant = useMemo(() => role === "assistant", [role]);

  return (
    <div className={clsx(styles.messageBlock, !isAssistant && styles.user)}>
      {isAssistant && (
        <div className={styles.messageLogoBlock}>
          <img src={DemandaLogo} className={styles.messageLogo} />
        </div>
      )}
      <div className={clsx(styles.messageText, !isAssistant && styles.user)}>
        {text}
      </div>
    </div>
  );
};
export default memo(DemandaMessage);
