import { ITopicAuthority } from "@/interfaces/topic-authority.interface";
import { ITopicAuthorityStore } from "@/interfaces/topic-authority-store.interface";

const createTopicAuthorityStore: () => ITopicAuthorityStore = () => ({
  selectedTopicTabIndex: 0,
  selectedDataTabIndex: 0,
  selectedPidTopics: [] as ITopicAuthority[],
  selectedTopic: {} as ITopicAuthority,
  setSelectedTopicTabIndex(value: number) {
    this.selectedTopicTabIndex = value;
  },
  setSelectedDataTabIndex(value: number) {
    this.selectedDataTabIndex = value;
  },
  setSelectedPidTopics(topics) {
    this.selectedPidTopics = topics;
  },
  setSelectedTopic(topic) {
    this.selectedTopic = topic;
  }
});

export default createTopicAuthorityStore;
