import clsx from "clsx";
import React, { useCallback, useMemo } from "react";
import Tabs from "@/components/shared/Tabs";
import DataOrder from "@/components/topic_authority/components/DataOrder";
import { PointedTooltip } from "@/components/shared/PointedTooltip";
import {
  DataOrderType,
  ITopicAuthority
} from "@/interfaces/topic-authority.interface";
import { ITopicAuthorityStore } from "@/interfaces/topic-authority-store.interface";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import { useTopicAuthorityStore } from "@/hooks";
import { toUpperCaseFirstLetter } from "@/utilities/format";

import styles from "./TopicAuthorityContent.module.scss";

const tabs: { title: DataOrderType }[] = [
  { title: "ranking" },
  { title: "priority" },
  { title: "network" }
];

interface ITopicAuthorityDataProps {
  topic: ITopicAuthority;
}

function TopicAuthorityData({ topic }: ITopicAuthorityDataProps) {
  const {
    selectedDataTabIndex,
    setSelectedDataTabIndex
  }: ITopicAuthorityStore = useTopicAuthorityStore(
    (store: ITopicAuthorityStore) => ({
      selectedDataTabIndex: store.selectedDataTabIndex,
      setSelectedDataTabIndex: (value: number) =>
        store.setSelectedDataTabIndex(value)
    })
  );

  const handleTabClick = useCallback(
    (stopLoading: () => void, index: number) => {
      setSelectedDataTabIndex(index);
    },
    [setSelectedDataTabIndex]
  );

  const dataOrder = useMemo(() => {
    switch (tabs[selectedDataTabIndex].title) {
      case "ranking":
        return topic.dataOrder?.ranking;
      case "priority":
        return topic.dataOrder?.priority;
      case "network":
        return topic.dataOrder?.network;
      default:
        return {};
    }
  }, [
    selectedDataTabIndex,
    topic.dataOrder?.network,
    topic.dataOrder?.priority,
    topic.dataOrder?.ranking
  ]);

  const tooltipTitle = useMemo(() => {
    switch (tabs[selectedDataTabIndex].title) {
      case "ranking":
        return "This list displays all terms found in your Pillar Topic network whose ranking positions have changed since last week, divided into Position Increase and Position Decrease. Use this tab to watch rankings for key terms week over week.";
      case "priority":
        return "Priority is a proprietary metric for gauging how well a term helps generate topical authority for your Pillar Topic. When priority increases for a term, that term will receive higher weight when calculating your Topical Authority Score.";
      case "network":
        return "We refresh your Pillar Topic network data weekly. Sometimes, new keywords become relevant and should be considered for targeting with content. Other terms you have previously targeted may leave the network of relevance, no longer contributing to your Topic Authority Score.";
      default:
        return "";
    }
  }, [selectedDataTabIndex]);

  const tabItems = useMemo(
    () =>
      tabs.map((item) => ({
        ...item,
        title: toUpperCaseFirstLetter(item.title)
      })),
    []
  );

  return (
    <>
      <div
        className={clsx(styles.taContentHeader, styles.taContentHeaderRight)}
      >
        What changed since last week?
      </div>
      <div className="d-flex align-items-center justify-content-end mb-2">
        <PointedTooltip
          sx={{
            maxWidth: "250px",
            fontFamily: "'Roboto', sans-serif",
            fontSize: "10px",
            textAlign: "justify"
          }}
          title={tooltipTitle}
          placement="left"
        >
          <QuestionMarkIcon className={styles.pointer} />
        </PointedTooltip>
      </div>
      <div>
        <Tabs
          items={tabItems}
          onClick={handleTabClick}
          isDynamicSizes
          classes={{ tab: styles.taDataOrderTabItem }}
          selectedIndex={selectedDataTabIndex}
        />
        <DataOrder data={dataOrder} type={tabs[selectedDataTabIndex].title} />
      </div>
    </>
  );
}

export default TopicAuthorityData;
