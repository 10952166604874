import { DATA_ORDER_STATUS } from "@/interfaces/topic-authority.interface";

export function getDataOrderStatusClassName(
  dataOrderStatus: DATA_ORDER_STATUS
) {
  if (!dataOrderStatus) {
    return "";
  }

  return dataOrderStatus.toLowerCase();
}
