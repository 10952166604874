import {
  IBlogs,
  IContentPillars,
  ISubPillars
} from "@/interfaces/content-pillars.interface";
import { Form, Table } from "react-bootstrap";
import PillarRow from "./PillarRow";
import SubPillarRow from "./SubPillarRow";
import "./pillar_strategy_table.scss";
import BlogRowFirstTier from "./BlogRowFirstTier";
import { useEffect, useState } from "react";
import {
  IPillarStrategyStore,
  SelectedItem
} from "@/interfaces/pillar-strategy-store.interface";
import { usePillarStrategyStore } from "@/hooks";

interface PSTableProps {
  pillar: IContentPillars;
  writeAccess: boolean;
}

function PillarStrategyTable({ pillar, writeAccess }: PSTableProps) {
  const [selectedItems, setSelectedItems] = useState({
    blog: [],
    sub_pillar: []
  } as SelectedItem);
  const [selectAll, setSelectAll] = useState("false");
  const [selectAllCheck, setSelectAllCheck] = useState(false);
  const { selectedTopics, searchedTopic, setSelectedTopics } =
    usePillarStrategyStore((store: IPillarStrategyStore) => ({
      selectedTopics: store.selectedTopics,
      searchedTopic: store.searchedTopic,
      setSelectedTopics: store.setSelectedTopics
    }));

  const selectedRows = (
    item: IBlogs | ISubPillars,
    type: "blog" | "sub_pillar",
    keep = true
  ) => {
    if (item.id && keep) {
      const id = item.id;
      // look through selected items and get the index of the item
      if (selectedItems[type].includes(id)) {
        const index = selectedItems[type].indexOf(id);
        if (index > -1) {
          selectedItems[type].splice(index, 1);
        }
        // if the item is not selected, add it to the selected items
      } else {
        selectedItems[type].push(id);
      }
      // how many total top-level blogs are there?
      const selectedLength =
        selectedItems.blog.length + selectedItems.sub_pillar.length;
      let total = 0;
      if (pillar.blogs) {
        for (const blog of pillar.blogs) {
          if (
            blog.topic.toLowerCase().includes(searchedTopic) ||
            blog.url.toLowerCase().includes(searchedTopic) ||
            "blog".includes(searchedTopic)
          ) {
            total++;
          }
        }
      }
      // how many total sub_pillars are there?
      if (pillar.sub_pillars) {
        for (const subPillar of pillar.sub_pillars) {
          if (
            subPillar.topic.toLowerCase().includes(searchedTopic) ||
            subPillar.url.toLowerCase().includes(searchedTopic) ||
            "sub-pillar".includes(searchedTopic)
          ) {
            total++;
          }
        }
      }

      let numberOfChildren = 0;
      // filter out all the sub-pillars and blogs that don't match the searchbox
      pillar?.sub_pillars
        ?.filter((subPillar) => {
          if ("sub-pillar".includes(searchedTopic)) {
            return true;
          }
          // if the sub-pillar has blogs, check if the the search is  "blogs", or if the search is in the blog topic or url
          if (subPillar.blogs) {
            if ("blog".includes(searchedTopic)) {
              return true;
            }
            for (const blog of subPillar.blogs) {
              if (
                blog.topic.toLowerCase().includes(searchedTopic) ||
                blog.url.toLowerCase().includes(searchedTopic)
              ) {
                return true;
              }
            }
          }
          // check if the search is in the sub-pillar topic or url
          return (
            subPillar.topic.toLowerCase().includes(searchedTopic) ||
            subPillar.url.toLowerCase().includes(searchedTopic)
          );
          // eslint-disable-next-line no-return-assign
        })
        .forEach((sp) => {
          if (sp.blogs) {
            sp.blogs.forEach((blog) => {
              numberOfChildren +=
                blog.topic.toLowerCase().includes(searchedTopic) ||
                "blog".includes(searchedTopic)
                  ? 1
                  : 0;
            });
          }
        });
      total += numberOfChildren;
      setSelectAllCheck(selectedLength === total);
      setSelectedTopics({ ...selectedItems });
      setSelectedItems({ ...selectedItems });
    } else if (item.id && !keep) {
      const id = item.id;
      if (selectedItems[type]?.includes(id)) {
        const index = selectedItems[type].indexOf(id);
        if (index > -1) {
          selectedItems[type].splice(index, 1);
        }
      }
    }
  };

  useEffect(() => {
    setSelectedTopics({ blog: [], sub_pillar: [] });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchedTopic, pillar]);

  // What does this do??
  useEffect(() => {
    if (
      selectedTopics.blog.length === 0 &&
      selectedTopics.sub_pillar.length === 0
    ) {
      setSelectedItems({ blog: [], sub_pillar: [] });
      setSelectAllCheck(false);
      setSelectAll("false");
    }
  }, [selectedTopics]);

  const handleSelectAll = () => {
    if (selectAllCheck) {
      setSelectAllCheck(false);
      setSelectAll("false");
    } else {
      setSelectAllCheck(true);
      setSelectAll("true");
    }
  };

  return (
    <div id="ps-table">
      <Table style={{ width: "100%" }} hover borderless>
        <thead className="ps-table-heading">
          <tr>
            <th style={{ width: "44%", paddingLeft: "22px" }}>
              <Form.Check
                style={{
                  position: "relative",
                  left: "-5px",
                  float: "left"
                }}
                className="rowCheckbox"
                id="flexCheckDefault"
                type={"checkbox"}
                checked={selectAllCheck}
                onChange={handleSelectAll}
              />
              <span style={{ paddingLeft: "25px" }}>Name</span>
            </th>
            <th style={{ width: "15%" }}>Type</th>
            <th style={{ width: "15%" }}>Content Brief</th>
            <th style={{ width: "26%" }}>Published Url</th>
          </tr>
        </thead>
        <tbody>
          <PillarRow pillar={pillar} writeAccess={writeAccess} />
          {pillar?.blogs
            ?.filter((blog) => {
              if ("blog".includes(searchedTopic)) {
                return true;
              }
              return (
                blog?.topic?.toLowerCase().includes(searchedTopic) ||
                blog?.url?.toLowerCase().includes(searchedTopic)
              );
            })
            .sort((a, b) => {
              if (a.id && b.id) {
                return b.id - a.id;
              }
              return 0;
            })
            .map((blog, index) => (
              <BlogRowFirstTier
                parentPillar={pillar}
                blog={blog}
                key={index}
                writeAccess={writeAccess}
                selected={selectedRows}
                selectAll={selectAll}
              />
            ))}
          {pillar?.sub_pillars
            ?.filter((subPillar) => {
              if ("sub-pillar".includes(searchedTopic)) {
                return true;
              }
              if (subPillar.blogs) {
                if ("blog".includes(searchedTopic)) {
                  return true;
                }
                for (const blog of subPillar.blogs) {
                  if (
                    blog?.topic?.toLowerCase()?.includes(searchedTopic) ||
                    blog?.url?.toLowerCase()?.includes(searchedTopic)
                  ) {
                    return true;
                  }
                }
              }
              return (
                subPillar?.topic?.toLowerCase()?.includes(searchedTopic) ||
                subPillar?.url?.toLowerCase()?.includes(searchedTopic)
              );
            })
            .sort((a, b) => {
              if (a.id && b.id) {
                return b.id - a.id;
              }
              return 0;
            })
            .map((subPillar, index) => (
              <SubPillarRow
                parentPillar={pillar}
                subPillar={subPillar}
                key={index}
                writeAccess={writeAccess}
                selected={selectedRows}
                selectAll={selectAll}
              />
            ))}
        </tbody>
      </Table>
    </div>
  );
}

export default PillarStrategyTable;
