import { StoreContext } from "../stores";
import useStore from "./useStore";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useDemandaAIStore = (dataSelector: any) =>
  useStore(
    StoreContext,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (contextData: any = {}) => contextData.demandaAIStore,
    dataSelector
  );

export default useDemandaAIStore;
