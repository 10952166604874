import CircularProgress from "@mui/material/CircularProgress";
import { Link } from "react-router-dom";
import clsx from "clsx";

import styles from "./TopicAuthorityContent.module.scss";

function TopicAuthorityContentEmpty({ loading }: { loading: boolean }) {
  return (
    <div
      className={clsx(
        styles.taContentContainer,
        styles.taContentEmptyContainer
      )}
    >
      <div className={styles.taContentEmpty}>
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <div className={styles.mainMessage}>No Topic Authority Yet</div>
            <div className={styles.secondaryMessage}>
              To learn more about topic authority,{" "}
              <Link to="#" target="_blank">
                click here
              </Link>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default TopicAuthorityContentEmpty;
