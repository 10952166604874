import React, { memo } from "react";
import clsx from "clsx";
import Stack from "@mui/material/Stack";
import { Gauge, gaugeClasses } from "@mui/x-charts/Gauge";

import styles from "./ScoreChart.module.scss";
import { ScoreType } from "@/interfaces/topic-authority.interface";
import theme from "@/themes/theme";

interface IScoreChartProps {
  value?: number;
  type?: ScoreType;
  text?: string;
  className?: string;
}

const colors = {
  down: theme.palette.error.main,
  up: theme.palette.success.main,
  noChanges: theme.palette.info.main
};

function ScoreChart({
  value,
  type,
  text = "Topic Authority Score",
  className
}: IScoreChartProps) {
  return (
    <div>
      <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 1, md: 3 }}>
        <Gauge
          width={150}
          height={100}
          value={value}
          startAngle={-90}
          endAngle={90}
          innerRadius="88%"
          outerRadius="100%"
          cornerRadius="50%"
          sx={() => ({
            [`& .${gaugeClasses.valueText}`]: {
              fontSize: 40
            },
            [`& .${gaugeClasses.valueArc}`]: {
              fill: type ? colors[type] : theme.palette.grey.A400
            }
          })}
        />
      </Stack>
      <div className={clsx(styles.text, className)}>{text}</div>
    </div>
  );
}

export default memo(ScoreChart);
