/* eslint-disable max-lines */
import axios, { AxiosResponse } from "axios";
import { v4 as uuidv4 } from "uuid";
import Cookies from "universal-cookie";
const cookies = new Cookies();

import { IInsightsMorpheusRequest } from "../interfaces/insights.interface";
import { ISignupDetails } from "../interfaces/signup-details.interface";
import { ICompetitorsPayload } from "../interfaces/competitors-payload.interface";
import { IUser, IUpdateUser } from "../interfaces/user.interface";
import { IAcceptInvitePayload } from "../interfaces/accept-invite-payload.interface";
import { IKeywordList } from "../interfaces/keywords.interface";
import { IBookmark } from "../interfaces/navigation.interface";
import { ISavedOutline } from "../interfaces/saved_outline.interface";
import {
  IBlogs,
  IBriefStrategyPost,
  IContentPillars,
  IContentPillarsPost,
  ISubPillars
} from "../interfaces/content-pillars.interface";
import { IPostPDomain } from "../interfaces/pdomain.interface";
import { IBrief } from "@/interfaces/brief.interface";
import {
  IBriefSearchTerm,
  IOrderedSections
} from "@/interfaces/outline.interface";

const postUrl = async (
  url: string,
  data = {},
  options = {},
  authorization = true,
  host = process.env.REACT_APP_NEO_URL
) => {
  const final_url = host + url;
  let device_uuid = cookies.get("device_id");
  if (!device_uuid) {
    device_uuid = uuidv4();
    cookies.set("device_id", device_uuid, { path: "/" });
  }
  let access_token = null;
  if (authorization) {
    access_token = cookies.get("Access_Token");
    if (!access_token) {
      window.location.href = "/login";
    }
  }

  return await axios
    .post(final_url, data, {
      ...options,
      headers: {
        authorization: `Bearer ${access_token}`,
        "X-DeviceUUID": device_uuid
      }
    })
    .then((response) => {
      // if auth was required for this request, and we have an access token
      if (authorization && response?.headers?.["x-access-token"]) {
        if (cookies.get("Access_Token")) {
          cookies.remove("Access_Token", { path: "/" });
          cookies.set("Access_Token", response.headers["x-access-token"], {
            path: "/",
            domain: process.env.REACT_APP_SUBDOMAIN
          });
        } else {
          // direct to login
          window.location.href = "/login";
        }
      }
      return response;
    })
    .catch((err) => {
      if (err.response.status === 401) {
        cookies.remove("Access_Token", {
          path: "/",
          domain: process.env.REACT_APP_SUBDOMAIN
        });
        cookies.remove("Access_Token", { path: "/" });
        window.location.href = "/login";
      }
      throw err;
    });
};

export const postLogout = async () => {
  const url = "/api/v1/logout";
  return await postUrl(url, {}, {}, true).catch((err) => {
    throw err;
  });
};

export const login = async (
  email: string,
  password: string,
  duration = 1
): Promise<AxiosResponse> => {
  const url = "/api/v1/authenticate";
  const body = {
    duration,
    email,
    password
  };
  const options = {
    headers: {
      "Content-Type": "application/json"
    }
  };
  return await postUrl(url, body, options, false);
};

export const postAssignUserToBrief = async (
  brief_id: number,
  user_id: number
): Promise<AxiosResponse> => {
  const url = "/api/v1/assign-brief";
  const body = {
    user_id,
    brief_id
  };
  const options = {
    headers: {
      "Content-Type": "application/json"
    }
  };
  return await postUrl(url, body, options);
};

export const postSetBriefState = async (
  brief_id: number,
  state: string
): Promise<AxiosResponse> => {
  const url = "/api/v1/set-brief-status";
  const body = {
    state,
    brief_id
  };
  const options = {
    headers: {
      "Content-Type": "application/json"
    }
  };
  return await postUrl(url, body, options);
};

export const postSetBriefType = async (
  brief_id: number,
  type: string
): Promise<AxiosResponse> => {
  const url = "/api/v1/set-brief-type";
  const body = {
    type,
    brief_id
  };
  const options = {
    headers: {
      "Content-Type": "application/json"
    }
  };
  return await postUrl(url, body, options);
};

export const loginGoogle = async (
  id_token: string,
  duration = 1
): Promise<AxiosResponse> => {
  const url = "/api/v1/authenticate-google";
  const body = {
    duration,
    id_token
  };
  const options = {
    headers: {
      "Content-Type": "application/json"
    }
  };
  return await postUrl(url, body, options, false);
};

export const loginOTP = async (otp_uuid: string): Promise<AxiosResponse> => {
  const url = "/api/v1/validate-otp";
  const body = {
    otp_uuid: otp_uuid.substring(1)
  };
  const options = {
    headers: {
      "Content-Type": "application/json"
    }
  };
  return await postUrl(url, body, options);
};

export const refresh = async (): Promise<AxiosResponse> => {
  const url = "/api/v1/refresh-access-token";
  const options = {
    headers: {
      "Content-Type": "application/json"
    }
  };
  return await postUrl(url, {}, options);
};

export const postBookmark = async (
  name: string,
  url: string,
  pid: number
): Promise<IBookmark> => {
  const postAddr = "/api/v1/user_bookmarks";
  const body = {
    name,
    url,
    pid,
    is_morpheus: true
  };
  const options = {
    headers: {
      "Content-Type": "application/json"
    }
  };
  return await postUrl(postAddr, body, options).then(
    (response: AxiosResponse): IBookmark => response.data as IBookmark
  );
};

export const postInsight = async (
  insightData: IInsightsMorpheusRequest
): Promise<AxiosResponse> => {
  const postAddr = "/api/v1/insights";
  const body = insightData;
  const options = {
    headers: {
      "Content-Type": "application/json"
    }
  };
  return await postUrl(postAddr, body, options);
};

export const postGenerateBrief = async (
  phrase: string,
  pid: number
): Promise<AxiosResponse> => {
  const url = "/api/v1/generate-content-brief";
  const body = {
    phrase,
    pid
  };
  const options = {
    headers: {
      "Content-Type": "application/json"
    }
  };
  return await postUrl(url, body, options);
};

export const updateUnfinishedInsights = async (
  pid: number,
  insightIds: number[]
): Promise<AxiosResponse> => {
  const postAddr = `/api/v1/unfinishedinsights/${pid}`;
  const body = { unfinishedIds: JSON.stringify(insightIds) };
  const options = {
    headers: {
      "Content-Type": "application/json"
    }
  };
  return await postUrl(postAddr, body, options);
};

export const refreshInsight = async (
  insightId: number
): Promise<AxiosResponse> => {
  const postAddr = "/api/v1/insight-refresh";
  const body = { id: insightId };
  const options = {
    headers: {
      "Content-Type": "application/json"
    }
  };
  return await postUrl(postAddr, body, options);
};

export const forgotPassword = async (
  email: string,
  token: string
): Promise<AxiosResponse> => {
  const url = "/api/v1/send-password-reset";
  const body = {
    email,
    token
  };
  const options = {
    headers: {
      "Content-Type": "application/json"
    }
  };
  return await postUrl(url, body, options, false);
};

export const updatePassword = async (
  code: string,
  user_id: number,
  password: string,
  confirmPassword: string
): Promise<AxiosResponse> => {
  const url = "/api/v1/update-password";
  const body = {
    code,
    user_id,
    password,
    confirmPassword
  };
  const options = {
    headers: {
      "Content-Type": "application/json"
    }
  };
  return await postUrl(url, body, options, false);
};

export const submitSignup = async (
  signupDetails: ISignupDetails
): Promise<AxiosResponse> => {
  const url = "/api/v1/signup";
  const options = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  return await postUrl(url, signupDetails, options, false);
};

export const checkRecaptcha = async (token: string): Promise<AxiosResponse> => {
  const url = "/api/v1/recaptcha";
  const options = {
    headers: {
      "Content-Type": "application/json"
    }
  };
  const body = { token };

  return await postUrl(url, body, options, false);
};
export const postSavedOutline = async (
  outline: ISavedOutline
): Promise<AxiosResponse> => {
  const url = "/api/v1/post-saved-outline";
  const options = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  return await postUrl(url, outline, options);
};

export const addCompetitors = async (
  payload: ICompetitorsPayload
): Promise<AxiosResponse> => {
  const url = `/api/v1/competitors`;
  const options = {
    headers: {
      "Content-Type": "application/json"
    }
  };
  return await postUrl(url, payload, options);
};

export const postInvitedUsers = async (payload: IUser[]) => {
  const url = "/api/v1/users/invite";
  return await postUrl(url, payload);
};

export const deleteUserFromPid = async (payload: IUser) => {
  const url = "/api/v1/users/delete-invite";
  return await postUrl(url, payload);
};

export const postAcceptInvite = async (payload: IAcceptInvitePayload) => {
  const url = "/api/v1/users/accept-invite";
  return await postUrl(url, payload, {}, false);
};

export const postKeywordList = async (
  keywordList: IKeywordList[],
  pid: number
) => {
  const url = `/api/v1/keywords-list/${pid}`;
  return await postUrl(url, keywordList)
    .then((res) => res.data)
    .catch((err) => {
      console.error(err);
      throw err;
    });
};

export const updateProfile = async (userInfo: IUpdateUser, id: number) => {
  const url = `/api/v1/update-user/${id}`;
  return await postUrl(url, userInfo)
    .then((res) => res.data)
    .catch((err) => {
      console.error(err);
      return err;
    });
};

export const postContentPillars = async (
  payload: IContentPillarsPost,
  pid: number
) => {
  const url = `/api/v1/content-pillars/${pid}`;
  return await postUrl(url, payload)
    .then((res) => res.data)
    .catch((err) => {
      console.error(err);
      throw err;
    });
};

export const updateUnfinishedPillars = async (
  pillars: IContentPillars[],
  pid: number
) => {
  const url = `/api/v1/update-unfinished-pillars/${pid}`;
  return await postUrl(url, { pillars })
    .then((res) => res.data)
    .catch((err) => {
      console.error(err);
      throw err;
    });
};

export const resendVerificationEmail = async (email: IUpdateUser) => {
  const url = `/api/v1/resend-email-verification`;
  const data = {
    email
  };
  return await postUrl(url, data)
    .then((res) => res.data)
    .catch((err) => {
      console.error(err);
      return err;
    });
};

export const postNewAccount = async (data: IPostPDomain) => {
  const url = "/api/v1/account";
  return await postUrl(url, data);
};

export const postNewPdomain = async (data: IPostPDomain) => {
  const url = "/api/v1/pdomain";
  return await postUrl(url, data).catch((err) => {
    console.error(err);
    throw err;
  });
};

export const postSubPillar = async (
  data: ISubPillars,
  pid: number
): Promise<ISubPillars> => {
  const url = "/api/v1/sub-pillar";
  return await postUrl(url, { ...data, pid })
    .then((res: AxiosResponse): ISubPillars => res.data as ISubPillars)
    .catch((err) => {
      console.error(err);
      throw err;
    });
};

export const postBlog = async (data: IBlogs, pid: number): Promise<IBlogs> => {
  const url = "/api/v1/blog";
  return await postUrl(url, { ...data, pid })
    .then((res: AxiosResponse): IBlogs => res.data as IBlogs)
    .catch((err) => {
      console.error(err);
      throw err;
    });
};

export const postBriefToStrategy = async (
  data: IBriefStrategyPost
): Promise<IBrief> => {
  const url = "/api/v1/add-brief-to-strategy";
  return await postUrl(url, data)
    .then((res: AxiosResponse): IBrief => res.data as IBrief)
    .catch((err) => {
      console.error(err);
      throw err;
    });
};
export const postMFA = async (mfa_code: string) => {
  const url = "/api/v1/save-mfa";
  return await postUrl(url, { mfa_code });
};

export const postAuthorizeMfa = async (mfa_code: string) => {
  const url = "/api/v1/validate-mfa";
  return await postUrl(url, { mfa_code });
};

export const updateUnfinishedBriefs = async (
  pid: number,
  briefIds: number[]
): Promise<AxiosResponse> => {
  const postAddr = `/api/v1/unfinishedbriefs/${pid}`;
  const body = { unfinishedIds: JSON.stringify(briefIds) };
  const options = {
    headers: {
      "Content-Type": "application/json"
    }
  };
  return await postUrl(postAddr, body, options);
};

export const addBlogToPillarStrategy = async (
  pid: number,
  pillar_topic: string,
  query: string
): Promise<AxiosResponse> => {
  const postAddr = `/api/v1/add-blog-to-pillar-strategy`;
  const body = { pillar_topic, query, pid };
  const options = {
    headers: {
      "Content-Type": "application/json"
    }
  };
  return await postUrl(postAddr, body, options);
};

export const addSubPillarToPillarStrategy = async (
  pid: number,
  pillar_topic: string,
  query: string
): Promise<AxiosResponse> => {
  const postAddr = `/api/v1/add-sub-pillar-to-pillar-strategy`;
  const body = { pillar_topic, query, pid };
  const options = {
    headers: {
      "Content-Type": "application/json"
    }
  };
  return await postUrl(postAddr, body, options);
};

export const postContentBriefsStepOne = async (
  pid: number,
  brief_id: number,
  keywords: IBriefSearchTerm[]
): Promise<{
  ordered_sections: IOrderedSections;
  targeted_keywords: IBriefSearchTerm[];
  targeted_questions: IBriefSearchTerm[];
}> => {
  const postAddr = "/api/v1/save-cb-step-one";
  const body = { pid, brief_id, keywords };
  const options = {
    headers: {
      "Content-Type": "application/json"
    }
  };
  return await postUrl(postAddr, body, options)
    .then(
      (res) =>
        res.data as {
          ordered_sections: IOrderedSections;
          targeted_keywords: IBriefSearchTerm[];
          targeted_questions: IBriefSearchTerm[];
        }
    )
    .catch((err) => {
      console.error(err);
      return err;
    });
};
export const postContentBriefsStepTwo = async (
  pid: number,
  brief_id: number,
  ordered_sections: IOrderedSections
) => {
  const postAddr = "/api/v1/save-cb-step-two";
  const body = { pid, brief_id, ordered_sections };
  const options = {
    headers: {
      "Content-Type": "application/json"
    }
  };
  return await postUrl(postAddr, body, options)
    .then(
      (res) =>
        res.data as {
          ordered_sections: IOrderedSections;
          targeted_keywords: IBriefSearchTerm[];
          targeted_questions: IBriefSearchTerm[];
        }
    )
    .catch((err) => {
      console.error(err);
      return err;
    });
};

export const postMetaDescription = async (
  brief_id: number,
  meta_description: string
) => {
  const postAddr = "/api/v1/post-saved-brief-meta-description";
  const body = { brief_id, meta_description };
  const options = {
    headers: {
      "Content-Type": "application/json"
    }
  };
  return await postUrl(postAddr, body, options)
    .then((res) => res.data as { ordered_sections: IOrderedSections })
    .catch((err) => {
      console.error(err);
      return err;
    });
};

export const updateContentBriefStep = async (
  brief_id: string,
  step: number
) => {
  const postAddr = "/api/v1/update-content-brief-step";
  const body = { brief_id, step };
  const options = {
    headers: {
      "Content-Type": "application/json"
    }
  };
  return await postUrl(postAddr, body, options)
    .then((res) => res)
    .catch((err) => {
      console.error(err);
      return err;
    });
};

export const generateXLSX = async (pillar: IContentPillars, pid: number) => {
  const postAddr = "/api/v1/generate-xlsx-from-strategy";
  const body = { pillar, pid };
  const final_url = process.env.REACT_APP_NEO_URL + postAddr;
  let device_uuid = cookies.get("device_id");
  if (!device_uuid) {
    device_uuid = uuidv4();
    cookies.set("device_id", device_uuid, { path: "/" });
  }
  const access_token = cookies.get("Access_Token");

  return await axios
    .post(final_url, body, {
      headers: {
        authorization: `Bearer ${access_token}`,
        "X-DeviceUUID": device_uuid,
        "Content-Type": "application/json"
      },
      responseType: "blob"
    })
    .then((res) => {
      const download_url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = download_url;
      link.setAttribute(
        "download",
        `${pillar.keyword.replace(/ /g, "_")}_pillar_strategy_export.xlsx`
      );
      document.body.appendChild(link);
      link.click();
    })
    .catch((err: Error) => {
      console.error(err);
      throw err;
    });
};

export const addOrUpdateNotes = async (
  outline_status_id: number,
  id: number | null,
  note: string
) => {
  const postAddr = "/api/v1/add-or-update-notes";
  const body = { outline_status_id, id, note };
  const options = {
    headers: {
      "Content-Type": "application/json"
    }
  };
  return await postUrl(postAddr, body, options)
    .then((res) => res)
    .catch((err) => {
      console.error(err);
      return err;
    });
};
export const addOrUpdateCTAs = async (
  id: number | null,
  saved_brief_id: number,
  display_text: string,
  href: string
) => {
  const postAddr = "/api/v1/add-or-update-CTAs";
  const body = { saved_brief_id, id, display_text, href };
  const options = {
    headers: {
      "Content-Type": "application/json"
    }
  };
  return await postUrl(postAddr, body, options)
    .then((res) => res)
    .catch((err) => {
      console.error(err);
      return err;
    });
};

export const postSendSupportEmail = async (
  name: string,
  email: string,
  emailBody: string
): Promise<AxiosResponse> => {
  const postAddr = `/api/v1/send-support-email`;
  const body = { name, email, emailBody };
  const options = {
    headers: {
      "Content-Type": "application/json"
    }
  };
  return await postUrl(postAddr, body, options);
};

export const createCompletionStream = async (message: string) =>
  await fetch("http://localhost:3000/completions", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json"
    },
    body: JSON.stringify({
      message
    })
  });
