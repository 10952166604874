/* eslint-disable max-lines */
/* eslint-disable complexity */
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import { RxAvatar } from "react-icons/rx";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Dispatch, SetStateAction, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useAppStore } from "../../../../hooks";
import {
  IconBookmark,
  IconHelpCircle,
  IconSettings
} from "@/assets/icons/svgr";
import CollapseItem from "./CollapseItem";
import { IAppStore } from "../../../../interfaces/app-store.interface";
import "../../../shared/userIcon.scss";

const LeftNavBottomList = ({
  sideMenuOpen,
  helpMenuOpen,
  settingsMenuOpen,
  bookmarksMenuOpen,
  profileSettingsMenuOpen,

  setProfileSettingsMenuOpen,
  setHelpMenuOpen,
  setSettingsMenuOpen,
  setBookmarksMenuOpen,
  handleDrawerOpen,
  handleDrawerClose
}: {
  sideMenuOpen: boolean;
  helpMenuOpen?: boolean;
  settingsMenuOpen?: boolean;
  bookmarksMenuOpen?: boolean;
  profileSettingsMenuOpen?: boolean;
  setProfileSettingsMenuOpen?: Dispatch<SetStateAction<boolean>>;
  setHelpMenuOpen?: Dispatch<SetStateAction<boolean>>;
  setSettingsMenuOpen?: Dispatch<SetStateAction<boolean>>;
  setBookmarksMenuOpen?: Dispatch<SetStateAction<boolean>>;
  handleDrawerOpen: () => void;
  handleDrawerClose: () => void;
}): JSX.Element => {
  const [isUserHovered, setIsUserHovered] = useState<boolean>(false);
  const isMobile = useMediaQuery({ query: `(max-width: 1024px)` });
  const href = window.location.href;
  const { user } = useAppStore((store: IAppStore) => ({
    user: store.user
  }));
  return (
    <List
      sx={{
        bottom: { sm: "0" },
        paddingTop: 0,
        paddingBottom: isMobile ? 0 : "20px"
      }}
    >
      {["Bookmark", "Help", "Settings"].map((text) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const [isHovered, setIsHovered] = useState<boolean>(false);
        return (
          <ListItem
            key={text}
            disablePadding
            sx={{
              color: "#373F50",
              display: "block",
              background:
                (text === "Bookmark" && bookmarksMenuOpen) ||
                (text === "Help" && helpMenuOpen) ||
                (text === "Settings" && settingsMenuOpen) ||
                (text === "Settings" && href.includes("settings"))
                  ? "#E1F5FF"
                  : "",
              "&:hover": {
                background: "#E1F5FF",
                color: "#008CCE"
              }
            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <ListItemButton
              sx={{
                minHeight: 37,
                height: "37px",
                justifyContent: sideMenuOpen ? "initial" : "center",
                "&.MuiButtonBase-root:hover": {
                  bgcolor: "transparent"
                },
                px: 2.5
              }}
              onClick={() => {
                if (
                  setHelpMenuOpen &&
                  setSettingsMenuOpen &&
                  setBookmarksMenuOpen &&
                  setProfileSettingsMenuOpen
                ) {
                  setHelpMenuOpen(false);
                  setSettingsMenuOpen(false);
                  setBookmarksMenuOpen(false);
                  setProfileSettingsMenuOpen(false);
                  if (text === "Help" && !helpMenuOpen) {
                    setHelpMenuOpen(true);
                  }
                  if (text === "Settings" && !settingsMenuOpen) {
                    setSettingsMenuOpen(true);
                  }
                  if (text === "Bookmark" && !bookmarksMenuOpen) {
                    setBookmarksMenuOpen(true);
                  }
                }
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: sideMenuOpen ? "15px" : "auto",
                  justifyContent: "center",
                  color: isHovered ? "#008CCE" : "#373F50"
                }}
              >
                {text === "Bookmark" && (
                  <IconBookmark
                    style={{
                      width: "24px",
                      color:
                        isHovered || bookmarksMenuOpen ? "#008CCE" : "#373F50"
                    }}
                  />
                )}
                {text === "Help" && (
                  <IconHelpCircle
                    style={{
                      width: "24px",
                      color: isHovered || helpMenuOpen ? "#008CCE" : "#373F50"
                    }}
                  />
                )}
                {text === "Settings" && (
                  <IconSettings
                    style={{
                      width: "24px",
                      color:
                        isHovered ||
                        settingsMenuOpen ||
                        (text === "Settings" &&
                          href.includes("settings") &&
                          !helpMenuOpen &&
                          !bookmarksMenuOpen)
                          ? "#008CCE"
                          : "#373F50"
                    }}
                  />
                )}
              </ListItemIcon>
              <ListItemText
                primary={text}
                sx={{
                  opacity: sideMenuOpen ? 1 : 0,
                  color:
                    (text === "Bookmark" && bookmarksMenuOpen) ||
                    (text === "Help" && helpMenuOpen) ||
                    (text === "Settings" && settingsMenuOpen) ||
                    (text === "Settings" &&
                      href.includes("settings") &&
                      !helpMenuOpen &&
                      !bookmarksMenuOpen)
                      ? "#008CCE"
                      : ""
                }}
                primaryTypographyProps={{
                  fontSize: "17px",
                  fontFamily: "Source Sans Pro",
                  fontWeight:
                    isHovered ||
                    (text === "Bookmark" && bookmarksMenuOpen) ||
                    (text === "Help" && helpMenuOpen) ||
                    (text === "Settings" && settingsMenuOpen) ||
                    (text === "Settings" &&
                      href.includes("settings") &&
                      !helpMenuOpen &&
                      !bookmarksMenuOpen)
                      ? "bold"
                      : "normal",
                  lineHeight: "21px",
                  letterSpacing: "-0.015em"
                }}
              />
            </ListItemButton>
          </ListItem>
        );
      })}
      <ListItem
        key="username"
        disablePadding
        sx={{
          color: "#373F50",
          transform: "color 0ms linear 0s",
          display: "block",
          background:
            profileSettingsMenuOpen ||
            (href.includes("manage-profile") &&
              !helpMenuOpen &&
              !bookmarksMenuOpen &&
              !settingsMenuOpen)
              ? "#E1F5FF"
              : "",
          "&:hover": {
            background: "#E1F5FF",
            color: "#008CCE"
          }
        }}
        onMouseEnter={() => setIsUserHovered(true)}
        onMouseLeave={() => setIsUserHovered(false)}
      >
        <ListItemButton
          sx={{
            minHeight: 37,
            height: "45px",
            "&.MuiButtonBase-root:hover": {
              bgcolor: "transparent"
            }
          }}
          onClick={() => {
            if (
              setHelpMenuOpen &&
              setSettingsMenuOpen &&
              setBookmarksMenuOpen &&
              setProfileSettingsMenuOpen
            ) {
              setHelpMenuOpen(false);
              setSettingsMenuOpen(false);
              setBookmarksMenuOpen(false);
              setProfileSettingsMenuOpen(!profileSettingsMenuOpen);
            }
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: sideMenuOpen ? "10px" : "auto",
              justifyContent: "center",
              color: isUserHovered ? "#008CCE" : "#373F50",
              transform: "color 0ms linear 0s"
            }}
          >
            <div>
              {user.avatar_url && (
                <img src={user.avatar_url} className="nav-user-avatar"></img>
              )}
              {!user.avatar_url && (
                <RxAvatar style={{ width: "34px", height: "34px" }} />
              )}
            </div>
          </ListItemIcon>
          <Grid sx={{ paddingY: "5px" }}>
            <ListItemText
              className="nav-user-name"
              primary={user.name || "Username"}
              sx={{
                opacity: sideMenuOpen ? 1 : 0,
                marginTop: "5px",
                color:
                  profileSettingsMenuOpen ||
                  (href.includes("manage-profile") &&
                    !helpMenuOpen &&
                    !bookmarksMenuOpen &&
                    !settingsMenuOpen)
                    ? "#008CCE"
                    : ""
              }}
              primaryTypographyProps={{
                fontSize: "17px",
                fontFamily: "Source Sans Pro",
                fontWeight:
                  isUserHovered ||
                  profileSettingsMenuOpen ||
                  (href.includes("manage-profile") &&
                    !helpMenuOpen &&
                    !bookmarksMenuOpen &&
                    !settingsMenuOpen)
                    ? "bold"
                    : "normal",
                lineHeight: "21px",
                letterSpacing: "-0.015em",
                maxWidth: "200px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap"
              }}
            />
            <ListItemText
              primary={user.unique_id || ""}
              sx={{
                opacity: sideMenuOpen ? 1 : 0,
                marginTop: "-5px",
                color:
                  profileSettingsMenuOpen ||
                  (href.includes("manage-profile") &&
                    !helpMenuOpen &&
                    !bookmarksMenuOpen &&
                    !settingsMenuOpen)
                    ? "#008CCE"
                    : ""
              }}
              primaryTypographyProps={{
                fontSize: "10px",
                fontFamily: "Source Sans Pro",
                fontWeight:
                  isUserHovered ||
                  profileSettingsMenuOpen ||
                  (href.includes("manage-profile") &&
                    !helpMenuOpen &&
                    !bookmarksMenuOpen &&
                    !settingsMenuOpen)
                    ? "bold"
                    : "normal",
                lineHeight: "13px",
                letterSpacing: "-0.015em",
                maxWidth: "175px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap"
              }}
            />
          </Grid>
        </ListItemButton>
      </ListItem>
      <CollapseItem
        sideMenuOpen={sideMenuOpen}
        handleDrawerOpen={handleDrawerOpen}
        handleDrawerClose={handleDrawerClose}
      />
    </List>
  );
};
export default LeftNavBottomList;
