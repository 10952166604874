import * as React from "react";
import { useMemo } from "react";
import clsx from "clsx";
import { LineChart } from "@mui/x-charts/LineChart";
import theme from "@/themes/theme";
import Button from "@/components/shared/Button";
import DataNotFound from "@/components/topic_authority/components/DataOrder/DataNotFound";
import {
  ITopicAuthority,
  ITrendItem
} from "@/interfaces/topic-authority.interface";

import styles from "./TopicAuthorityContent.module.scss";

interface ITopicAuthorityTrendProps {
  topic: ITopicAuthority;
}

function TopicAuthorityTrend({ topic }: ITopicAuthorityTrendProps) {
  const chartData = useMemo(
    () =>
      topic?.trend?.reduce(
        (acc: { labels: string[]; data: number[] }, item: ITrendItem) => ({
          labels: [...acc.labels, item.label],
          data: [...acc.data, item.data]
        }),
        { labels: [], data: [] }
      ),
    [topic?.trend]
  );

  return (
    <div className={styles.taTrendContainer}>
      <div className={styles.taContentHeader}>Topic Authority Trend</div>
      {topic?.trend ? (
        <>
          <LineChart
            width={450}
            height={300}
            series={[
              { data: chartData?.data, color: theme.palette.primary.main }
            ]}
            xAxis={[{ scaleType: "point", data: chartData?.labels }]}
          />
          <div className={clsx(styles.btnContainer, styles.hide)}>
            <Button
              type="submit"
              size="large"
              color="primary"
              variant="contained"
              text="View and Download All Reports"
              onClick={() => console.log("View and Download All Reports")}
            />
          </div>
        </>
      ) : (
        <DataNotFound />
      )}
    </div>
  );
}

export default TopicAuthorityTrend;
