import { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import Authorization from "./routines/authorization";

import GetUserByToken from "./routines/get-user-by-token";
import isMFAValidated from "./routines/get-mfa-validated";
import { useAppStore } from "./hooks";
import DJLoadingGif from "./components/shared/DJLoadingGif";
import { addOrUpdateUrlParam } from "./routines/addOrUpdateUrl";
import { IAppStore } from "./interfaces/app-store.interface";

const PrivateRoute = ({ children }: { children: JSX.Element }): JSX.Element => {
  const [finished, setFinished] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [mfaEnabled, setMfaEnabled] = useState(false);
  const location =
    window.location.pathname + window.location.search + window.location.hash;

  const { user, setUser, selectedPidObject } = useAppStore(
    (store: IAppStore) => ({
      user: store.user,
      setUser: store.setUser,
      selectedPidObject: store.selectedPidObject
    })
  );

  useEffect(() => {
    if (selectedPidObject.id) {
      addOrUpdateUrlParam("pid", selectedPidObject.id);
      // Store pid in local storage so on page refresh or any other context
      // clearing action the pid will persist
      localStorage.setItem("selectedPid", selectedPidObject.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname, selectedPidObject]);

  useEffect(() => {
    const fetchData = async () => {
      await Authorization()
        .then(async () => {
          let udb = user;
          if (!user?.id) {
            await GetUserByToken()
              .then((res) => {
                udb = res;
                setUser(udb);
              })
              .catch((err: Error) => {
                console.error(err);
              });
          }
          if (udb.otp_enabled) {
            const mfa_status = await isMFAValidated();
            if (mfa_status) {
              setIsAuthenticated(true);
              setMfaEnabled(true);
              setFinished(true);
            } else {
              setIsAuthenticated(true);
              setMfaEnabled(false);
              setFinished(true);
            }
          } else {
            setIsAuthenticated(true);
            setMfaEnabled(true);
            setFinished(true);
          }
        })
        .catch((err: Error) => {
          console.error(err);
          setIsAuthenticated(false);
          setMfaEnabled(false);
          setFinished(true);
        });
    };

    if (!finished) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finished]);

  return (
    <>
      {!finished && (
        <div className="main-loading-container">
          <DJLoadingGif />
        </div>
      )}
      {finished && isAuthenticated && mfaEnabled && children}
      {finished && !isAuthenticated && (
        <Navigate to={"/login"} state={{ from: location }} />
      )}
      {finished && isAuthenticated && !mfaEnabled && <Navigate to={"/mfa"} />}
    </>
  );
};

export default PrivateRoute;
