/* eslint-disable max-lines */
/* eslint-disable indent */
// return the pillar strategy page (for viewing the pillar strategy)
// https://www.figma.com/file/uL9EamzeHcMlMRe5Gj2Wh6?embed_host=share&kind=&node-id=121%3A2892&t=hdr7ALvZ7s6NGEdQ-1
// it has a heading at the top with a button to "manage pillar topics"
// then it has an accessible component that displays a different pillar strategy table depending on the pillar strategy selected
// the pillar strategy is selected with a carousel of pillar strategy tabs above the table
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAppStore, useEffectAsync, usePillarStrategyStore } from "@/hooks";
import "./pillar_strategy.scss";
import { IPillarStrategyStore } from "@/interfaces/pillar-strategy-store.interface";
import { Link, useNavigate } from "react-router-dom";
import { IAppStore } from "@/interfaces/app-store.interface";
import { getPillarStrategy } from "@/api/get";
import { generateXLSX } from "@/api/post";
import { IContentPillars } from "@/interfaces/content-pillars.interface";
import PillarStrategyTableEmpty from "./components/PillarStrategyTableEmpty";
import PillarStrategyTable from "./components/PillarStrategyTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowsUpDownLeftRight,
  faEllipsis,
  faDownload,
  faExchange
} from "@fortawesome/free-solid-svg-icons";
import MoveItemsModal from "./components/MoveItemsModal";
import DeleteFromStrategyModal from "./components/DeleteFromStrategyModal";
import ConvertItems from "./components/ConvertItemsModal";
import { ActionTrash } from "@/assets/icons/svgr";
import isEmpty from "lodash/isEmpty";
import LoadingNewlyAddedTopicModal from "./components/LoadingNewlyAddedTopicModal";
import { Dropdown } from "react-bootstrap";
import Tabs from "@/components/shared/Tabs";

const PillarStrategy = () => {
  // fetch pillar strategy table data
  const {
    selectedPidPillarTopics,
    searchedTopic,
    selectedTopics,
    selectedTopicTabIndex,
    setSelectedTopicTabIndex,
    setSelectedPidPillarTopics,
    setSearchedTopic,
    setExpandAll,
    setCollapseAll
  }: IPillarStrategyStore = usePillarStrategyStore(
    (store: IPillarStrategyStore) => ({
      selectedTopicTabIndex: store.selectedTopicTabIndex,
      setSelectedTopicTabIndex: (value: number) =>
        store.setSelectedTopicTabIndex(value),
      selectedPidPillarTopics: store.selectedPidPillarTopics,
      searchedTopic: store.searchedTopic,
      selectedTopics: store.selectedTopics,
      setSelectedPidPillarTopics: (value: IContentPillars[]) =>
        store.setSelectedPidPillarTopics(value),
      setSearchedTopic: (value: string) => store.setSearchedTopic(value),
      setExpandAll: (value: boolean) => store.setExpandAll(value),
      setCollapseAll: (value: boolean) => store.setCollapseAll(value)
    })
  );
  const [loading, setLoading] = useState(true);
  const [showMoveItemsModal, setShowMoveItemsModal] = useState<
    undefined | boolean
  >();
  const [showDeleteItemsModal, setShowDeleteItemsModal] =
    useState<boolean>(false);
  const [showConvertItemsModal, setShowConvertItemsModal] =
    useState<boolean>(false);
  const [disableMove, setDisableMove] = useState(true);
  const [disableDelete, setDisableDelete] = useState(true);
  const [disableConvert, setDisableConvert] = useState(true);
  const [selectedTopicIndex, setSelectedTopicIndex] = useState(0);
  const [fetchingNewTopic, setFetchingNewTopic] = useState<boolean>(false);
  const [topicAddedForPillarStrategy, setTopicAddedForPillarStrategy] =
    useState<string>("");
  const [topicWasFound, setTopicWasFound] = useState<boolean>(false);
  const navigate = useNavigate();

  const { selectedPidObject, user, setShowAlertBanner } = useAppStore(
    (store: IAppStore) => ({
      selectedPidObject: store.selectedPidObject,
      user: store.user,
      setShowAlertBanner: store.setShowAlertBanner
    })
  );

  useEffect(() => {
    const getPillarStrategyData = async () => {
      setLoading(true);
      await getPillarStrategy(selectedPidObject.id)
        .then((response) => {
          setSelectedPidPillarTopics(response);
        })
        .catch((e) => {
          console.error("error:", e);
          setShowAlertBanner(true, {
            message: `Error fetching pillar strategy, please try again. If problem persists please contact customer success.`,
            severity: "error"
          });
        });

      setLoading(false);
    };
    if (selectedPidObject?.id) {
      getPillarStrategyData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPidObject]);

  // Manage Pillar Strategy page pops up a modal telling the user that the sub-pillar/blog is being added and polls neo until the
  // sub-pillar/blog is added. query all of the sub-pillars and blogs under all pillar topics for that pid
  // until we find that one of them matches the string of text which was passed in the URL. Once complete, morpheus refreshes the data on the page.

  useEffectAsync(async () => {
    // check the url for a query string containing type=blog or type=subpillar
    const urlParams = new URLSearchParams(window.location.search);
    const type = urlParams.get("type");
    let q = urlParams.get("q");
    // decode the query string
    q = decodeURIComponent(q || "");
    setTopicAddedForPillarStrategy(q);
    if (type && q) {
      setLoading(true);
      setFetchingNewTopic(true);
      let numberOfTries = 0;
      setTopicWasFound(false);
      let was_found = false;
      const checkIfFinished = () => {
        // query neo for the sub-pillar or blog that matches the query string
        setTimeout(async () => {
          await getPillarStrategy(selectedPidObject.id).then((response) => {
            if (response.length > 0) {
              response.forEach((pillarTopic, i) => {
                if (
                  type === "subpillar" &&
                  !isEmpty(pillarTopic.sub_pillars) &&
                  pillarTopic.sub_pillars
                ) {
                  pillarTopic.sub_pillars.forEach((subPillar) => {
                    if (
                      subPillar.topic.replace("?", "") === q?.replace("?", "")
                    ) {
                      setTopicWasFound(true);
                      was_found = true;
                      // remove the query string from the url
                      window.history.replaceState(
                        {},
                        document.title,
                        window.location.pathname +
                          "?pid=" +
                          selectedPidObject.id
                      );
                      setSelectedTopicIndex(i);
                      setSelectedTopicTabIndex(i);
                      setFetchingNewTopic(false);
                      setLoading(false);
                    }
                  });
                } else if (
                  type === "blog" &&
                  !isEmpty(pillarTopic.blogs) &&
                  pillarTopic.blogs
                ) {
                  pillarTopic.blogs.forEach((blog) => {
                    if (blog.topic.replace("?", "") === q?.replace("?", "")) {
                      setTopicWasFound(true);
                      was_found = true;
                      // remove the query string from the url
                      window.history.replaceState(
                        {},
                        document.title,
                        window.location.pathname +
                          "?pid=" +
                          selectedPidObject.id
                      );
                      setSelectedTopicIndex(i);
                      setSelectedTopicTabIndex(i);
                      setFetchingNewTopic(false);
                      setLoading(false);
                    }
                  });
                }
              });
            }
            if (!was_found) {
              if (numberOfTries < 3) {
                numberOfTries += 1;
                checkIfFinished();
              } else {
                setFetchingNewTopic(false);
                setLoading(false);
                setShowAlertBanner(true, {
                  message: `Error adding ${q} to pillar strategy, please try again. If problem persists please contact customer success.`,
                  severity: "error"
                });
              }
            }
          });
        }, 3000);
      };

      // do an initial check and if not found, start recursive polling
      await getPillarStrategy(selectedPidObject.id).then((response) => {
        if (response.length > 0) {
          response.forEach((pillarTopic, i) => {
            if (
              type === "subpillar" &&
              !isEmpty(pillarTopic.sub_pillars) &&
              pillarTopic.sub_pillars
            ) {
              pillarTopic.sub_pillars.forEach((subPillar) => {
                if (subPillar.topic.replace("?", "") === q?.replace("?", "")) {
                  setTopicWasFound(true);
                  was_found = true;
                  // remove the query string from the url
                  window.history.replaceState(
                    {},
                    document.title,
                    window.location.pathname + "?pid=" + selectedPidObject.id
                  );
                  setSelectedTopicIndex(i);
                  setSelectedTopicTabIndex(i);
                  setFetchingNewTopic(false);
                  setLoading(false);
                }
              });
            } else if (
              type === "blog" &&
              !isEmpty(pillarTopic.blogs) &&
              pillarTopic.blogs
            ) {
              pillarTopic.blogs.forEach((blog) => {
                if (blog.topic.replace("?", "") === q?.replace("?", "")) {
                  setTopicWasFound(true);
                  was_found = true;
                  // remove the query string from the url
                  window.history.replaceState(
                    {},
                    document.title,
                    window.location.pathname + "?pid=" + selectedPidObject.id
                  );
                  setSelectedTopicIndex(i);
                  setSelectedTopicTabIndex(i);
                  setFetchingNewTopic(false);
                  setLoading(false);
                }
              });
            }
          });
        }
        if (!was_found) {
          numberOfTries += 1;
          checkIfFinished();
        }
      });
    }
  }, [selectedPidPillarTopics]);

  useEffect(() => {
    const isSubPillar =
      selectedTopics.sub_pillar.length > 0 && selectedTopics.blog.length === 0;
    const isBlog =
      selectedTopics.blog.length > 0 && selectedTopics.sub_pillar.length === 0;
    const isConvert = (!isBlog && isSubPillar) || (isBlog && !isSubPillar);

    setDisableMove(!isBlog || isSubPillar);
    setDisableDelete(
      selectedTopics.blog.length === 0 && selectedTopics.sub_pillar.length === 0
    );
    setDisableConvert(!isConvert);
  }, [selectedTopics]);

  const exportPillarStrategy = async () => {
    const strategy = selectedPidPillarTopics[selectedTopicTabIndex];
    await generateXLSX(strategy, selectedPidObject.id);
  };

  const tabItems = useMemo(
    () => selectedPidPillarTopics.map((item) => ({ title: item.keyword })),
    [selectedPidPillarTopics]
  );

  const handleTabClick = useCallback(
    (stopLoading: () => void, index: number) => {
      setSelectedTopicTabIndex(index);
      setSearchedTopic("");
      setExpandAll(true);
      setCollapseAll(false);
    },
    [setCollapseAll, setExpandAll, setSearchedTopic, setSelectedTopicTabIndex]
  );

  return (
    <div className="ps-container">
      <LoadingNewlyAddedTopicModal
        fetchingNewTopic={fetchingNewTopic}
        setFetchingNewTopic={setFetchingNewTopic}
        topicWasFound={topicWasFound}
        newTopic={topicAddedForPillarStrategy}
      />
      <div className="pillar-strategy__heading">
        <h1 className="pillar-strategy__heading__title">Pillar Strategy</h1>
        <button
          className="pillar-strategy__heading__button"
          onClick={() => navigate("/settings/content-pillars")}
        >
          Set Pillar Topics
        </button>
      </div>

      <div className="tabs-table-container">
        {selectedPidPillarTopics.length > 0 ? (
          <>
            {showMoveItemsModal && (
              <MoveItemsModal
                items={selectedTopics}
                sub_pillars={
                  selectedPidPillarTopics[selectedTopicTabIndex]?.sub_pillars
                }
                pillar={selectedPidPillarTopics[selectedTopicTabIndex]}
                setShowMoveItemsModal={setShowMoveItemsModal}
              />
            )}
            {showDeleteItemsModal && (
              <DeleteFromStrategyModal
                blogIds={selectedTopics.blog}
                subPillarIds={selectedTopics.sub_pillar}
                setShowDeleteModal={setShowDeleteItemsModal}
              />
            )}
            {showConvertItemsModal && (
              <div className="pillar-strategy__tabs">
                <ConvertItems
                  items={selectedTopics}
                  pillar={selectedPidPillarTopics[selectedTopicTabIndex]}
                  pid={selectedPidObject.id}
                  setShowConvertItemsModal={setShowConvertItemsModal}
                />
              </div>
            )}
            <div className="pillar-strategy__tabs">
              <Tabs
                items={tabItems}
                onClick={handleTabClick}
                selectedIndex={selectedTopicIndex}
              />
            </div>
            <div className="d-flex justify-content-start align-items-center utility-bar-container">
              <div
                className="input-container"
                style={{
                  width: "365px",
                  margin: "0px 17px 0px 22px"
                }}
              >
                <input
                  style={{ height: "48px" }}
                  id="search"
                  type="text"
                  className={"form-control"}
                  placeholder="You can search by Name, Type, or URL"
                  value={searchedTopic}
                  onChange={(e) => {
                    setSearchedTopic(e.target.value.toLowerCase());
                  }}
                />
              </div>
              <Link
                style={{
                  marginLeft: "8px",
                  fontSize: "16px",
                  width: "250px"
                }}
                className="dj-blue source-sans-pro"
                to={`/dashboard/content-recommendations?PillarTopic=${selectedPidPillarTopics[
                  selectedTopicTabIndex
                ].keyword.replace(" ", "+")}`}
              >
                Add To Pillar Strategy
              </Link>
              <div
                className="d-flex justify-content-end align-items-right"
                style={{ width: "100%" }}
              >
                <div>
                  {(selectedTopics.blog.length > 0 ||
                    selectedTopics.sub_pillar.length > 0) && (
                    <span>
                      {selectedTopics.blog.length +
                        selectedTopics.sub_pillar.length}{" "}
                      Selected:
                    </span>
                  )}
                  <Dropdown className="action_dropdown">
                    <Dropdown.Toggle id="dropdown-basic" size="sm">
                      Actions
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        className="move_blogs_button"
                        onClick={() => {
                          setShowMoveItemsModal(true);
                        }}
                        disabled={disableMove}
                        type="button"
                        style={
                          disableMove ? { color: "gray" } : { color: "#008cce" }
                        }
                      >
                        <FontAwesomeIcon icon={faArrowsUpDownLeftRight} /> Move
                      </Dropdown.Item>
                      <Dropdown.Item
                        type="button"
                        className="delete_items_button"
                        onClick={() => setShowDeleteItemsModal(true)}
                        disabled={disableDelete}
                        style={
                          disableDelete
                            ? { color: "gray", paddingLeft: "12px" }
                            : { color: "#db3960", paddingLeft: "12px" }
                        }
                      >
                        <ActionTrash style={{ width: "24px" }} /> Delete
                      </Dropdown.Item>
                      <Dropdown.Item
                        type="button"
                        className="move_blogs_button"
                        disabled={disableConvert}
                        onClick={() => setShowConvertItemsModal(true)}
                        style={
                          disableConvert
                            ? { color: "gray" }
                            : { color: "#008cce" }
                        }
                      >
                        <FontAwesomeIcon icon={faExchange} /> Change Type
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  <Dropdown className="collapse_dropdown">
                    <Dropdown.Toggle id="dropdown-basic" size="sm">
                      <FontAwesomeIcon icon={faEllipsis} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => {
                          setCollapseAll(false);
                          setExpandAll(true);
                        }}
                      >
                        Expand All Rows
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setExpandAll(false);
                          setCollapseAll(true);
                        }}
                      >
                        Collapse All Rows
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          exportPillarStrategy();
                        }}
                      >
                        <FontAwesomeIcon icon={faDownload} /> Export Table
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="spacer"></div>
        )}
        {!loading &&
        selectedPidPillarTopics.length &&
        !isEmpty(selectedPidPillarTopics[selectedTopicTabIndex]) &&
        (!isEmpty(selectedPidPillarTopics[selectedTopicTabIndex].blogs) ||
          !isEmpty(
            selectedPidPillarTopics[selectedTopicTabIndex].sub_pillars
          ) ||
          user?.dj_employee) ? (
          <PillarStrategyTable
            pillar={selectedPidPillarTopics[selectedTopicTabIndex]}
            writeAccess={user?.dj_employee}
          />
        ) : (
          <PillarStrategyTableEmpty loading={loading} />
        )}
      </div>
    </div>
  );
};

export default PillarStrategy;
