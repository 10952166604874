export function filterForUniqueInsights(insights) {
  const uniqueInsightsArray = [];
  for (const item of insights) {
    const isDuplicate = uniqueInsightsArray.find(
      (obj) => obj.query_string === item.query_string
    );
    if (!isDuplicate) {
      uniqueInsightsArray.push(item);
    }
  }
  return uniqueInsightsArray;
}
