/* eslint-disable max-lines */
import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { getPaginatedInsights, getInsightsBySearch } from "../../../api/get";
import {
  IErrorInsightArray,
  IInsights,
  IInsightsStore
} from "../../../interfaces/insights.interface";
import dayjs from "dayjs";
import InfiniteScroll from "react-infinite-scroll-component";
import InfinityScrollingLoading from "./InfinityScrollingLoading";
import Status from "./Status";
import useAppStore from "../../../hooks/useAppStore";
import { languageConvert } from "../utilities/language";
import { IPDomain } from "../../../interfaces/pdomain.interface";
import { IAppStore } from "../../../interfaces/app-store.interface";
import { computeDaysBetween } from "../utilities/date";
import useInsightsStore from "../../../hooks/useInsightsStore";
import { updateUnfinishedInsights } from "../../../api/post";
import DJLoadingGif from "../../shared/DJLoadingGif";
import { Link } from "react-router-dom";
import isEmpty from "lodash/isEmpty";
import InsightsQuery from "./InsightQuery";
import debounce from "lodash/debounce";

interface IProps {
  errorArr: IErrorInsightArray[];
}
const InsightsTable = ({ errorArr }: IProps) => {
  const [loading, setLoading] = useState(true);
  const [shadow, setShadow] = useState(false);
  const [loadingAdditional, setLoadingAdditional] = useState(false);
  const [isFreeUser, setIsFreeUser] = useState(false);
  const [search, setSearch] = useState("");
  const limit = 30;
  const workingStatuses = ["pending", "working"];
  const { selectedPidObject, selectedSubscriptionObject } = useAppStore(
    (store: IAppStore) => ({
      selectedPidObject: store.selectedPidObject,
      selectedSubscriptionObject: store.selectedSubscriptionObject,
      setSelectedPidObject: (value: IPDomain) =>
        store.setSelectedPidObject(value)
    })
  );
  const {
    insights,
    pendingInsights,
    unfinishedInsights,
    insightPid,
    insightPage,
    insightHasMore,
    setInsights,
    setInsightHasMore,
    setInsightPid,
    setInsightPage,
    setUnfinishedInsights
  } = useInsightsStore((store: IInsightsStore) => ({
    insights: store.insights,
    pendingInsights: store.pendingInsights,
    unfinishedInsights: store.unfinishedInsights,
    insightPid: store.insightPid,
    insightPage: store.insightPage,
    insightHasMore: store.insightHasMore,
    setInsights: (value: IInsights[]) => store.setInsights(value),
    setInsightPid: (value: number) => store.setInsightPid(value),
    setInsightPage: (value: number) => store.setInsightPage(value),
    setInsightHasMore: (value: boolean) => store.setInsightHasMore(value),
    setUnfinishedInsights: (value: number[]) =>
      store.setUnfinishedInsights(value)
  }));

  useEffect(() => {
    const fetchInsights = async () => {
      let data: IInsights[];
      if (selectedPidObject.id !== insightPid) {
        setInsights([]);
        setLoading(true);
        setInsightPid(selectedPidObject.id);
        setInsightPage(1);
        data = await getPaginatedInsights(selectedPidObject.id, 1, limit);
        setInsights(data);
      } else if (insights.length === 0) {
        setLoading(true);
        setInsightPage(1);
        data = await getPaginatedInsights(insightPid, 1, limit);
        setInsights(data);
      } else {
        data = await getPaginatedInsights(insightPid, insightPage, limit);
        setInsightPage(insightPage + 1);
        setInsights([...insights, ...data]);
      }
      setLoading(false);
      const fetchedUnfinishedInsights: number[] = [];
      for (let i = 0; i < data.length; i++) {
        const currInsight = data[i];
        if (
          computeDaysBetween(new Date(), new Date(currInsight.created_at)) > 1
        ) {
          break;
        }
        if (
          workingStatuses.includes(currInsight.status) &&
          currInsight.id &&
          !fetchedUnfinishedInsights.includes(currInsight.id)
        ) {
          fetchedUnfinishedInsights.push(currInsight.id);
        }
      }
      setUnfinishedInsights(fetchedUnfinishedInsights);
      if (data.length < 10) {
        setInsightHasMore(false);
      }
    };
    if (selectedPidObject.id) {
      fetchInsights();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPidObject]);

  useEffect(() => {
    // eslint-disable-next-line no-mixed-operators
    if (insights.length * 60 + 300 > window.innerHeight) {
      setShadow(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.innerHeight, insights.length]);

  useEffect(() => {
    setIsFreeUser(
      selectedSubscriptionObject?.cb_plan_id?.includes("free") ||
        isEmpty(selectedSubscriptionObject)
    );
  }, [selectedSubscriptionObject]);

  useEffect(() => {
    const checkUnfinished = setTimeout(async () => {
      if (unfinishedInsights.length) {
        const localInsightIds = unfinishedInsights;
        try {
          const response = await updateUnfinishedInsights(
            selectedPidObject.id,
            unfinishedInsights
          );
          const updatedInsights = response.data;
          setInsights(
            insights.map((insight: IInsights) => {
              if (localInsightIds.includes(insight.id)) {
                const foundUpdatedInsight = updatedInsights.find(
                  (uInsight: IInsights) => uInsight.id === insight.id
                );
                if (foundUpdatedInsight) {
                  return foundUpdatedInsight;
                }
              }
              return insight;
            })
          );
          const stillUnfinished = updatedInsights
            .filter((uInsight: IInsights) =>
              workingStatuses.includes(uInsight.status)
            )
            .map((uInsight: IInsights) => uInsight.id);
          setUnfinishedInsights(stillUnfinished);
        } catch (error) {
          console.error(error);
        }
      }
    }, 5000);
    return () => clearTimeout(checkUnfinished);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unfinishedInsights]);

  const errorStatuses = [
    "ERROR-RES",
    "ERROR-ETL",
    "ERROR-PRE",
    "ERROR-STALE",
    "ERROR-DUP",
    "ERROR-ERR"
  ];
  const workingStatus = ["pending", "working"];
  const pendingInsightsList = (): JSX.Element[] =>
    pendingInsights
      .filter((i: IInsights) => i.query_string.toLowerCase().includes(search))
      .map((insight: IInsights, index: number) => (
        <Grid
          className="insights-list"
          container
          key={index}
          style={{
            margin: "10px 0px",
            color: errorStatuses.includes(insight.status)
              ? "#B00020"
              : "#373F50"
          }}
        >
          <Grid item key={insight.id} md={4}>
            <InsightsQuery
              query={insight.query_string}
              length={55}
            ></InsightsQuery>
            <a></a>
          </Grid>
          <Grid item key={insight.location_name} md={2}>
            {insight.location_name}
          </Grid>
          <Grid item key={insight.language} md={2}>
            {insight.language ? languageConvert(insight.language) : "English"}
          </Grid>
          <Grid item key={insight.created_at} md={2}>
            {dayjs(insight.created_at).format("YYYY/MM/DD")}
          </Grid>
          <Grid item key={insight.status} md={2}>
            <Status
              insight={insight}
              status={insight.status}
              percentComplete={insight.percent_complete}
              daysSince={computeDaysBetween(
                new Date(),
                new Date(insight.created_at)
              )}
            />
          </Grid>
        </Grid>
      ));

  const insightsList = (): JSX.Element[] =>
    insights
      .filter((i: IInsights) => i.query_string.toLowerCase().includes(search))
      .map((insight: IInsights, index: number) => {
        const errorStale =
          workingStatus.includes(insight.status) &&
          computeDaysBetween(new Date(), new Date(insight.created_at)) > 1;
        return insight.id ? (
          <Grid
            className="insights-list"
            container
            key={index}
            style={{
              margin: "10px 0px",
              color:
                errorStatuses.includes(insight.status) || errorStale
                  ? "#B00020"
                  : "#373F50"
            }}
          >
            <Grid item key={insight.id} md={4}>
              {insight.status === "complete" ? (
                <Link
                  to={`/dashboard/topic-summary${
                    isFreeUser ? "-free" : ""
                  }?Topic=${insight.query_string.replace(/ /u, "_")}`}
                >
                  <InsightsQuery
                    query={insight.query_string}
                    length={55}
                  ></InsightsQuery>
                </Link>
              ) : (
                <InsightsQuery
                  query={insight.query_string}
                  length={55}
                ></InsightsQuery>
              )}{" "}
            </Grid>
            <Grid item key={insight.location_name} md={2}>
              {insight.location_name?.length
                ? insight.location_name
                : "United States"}
            </Grid>
            <Grid item key={insight.language} md={2}>
              {insight.language ? languageConvert(insight.language) : "English"}
            </Grid>
            <Grid item key={insight.created_at} md={2}>
              {dayjs(insight.created_at).format("YYYY/MM/DD")}
            </Grid>
            <Grid item key={insight.status} md={2}>
              <Status
                insight={insight}
                status={errorStale ? "ERROR-STALE" : insight.status}
                percentComplete={insight.percent_complete}
                daysSince={computeDaysBetween(
                  new Date(),
                  new Date(insight.created_at)
                )}
              />
            </Grid>
          </Grid>
        ) : null;
      });

  const erroredInsightsList = (): JSX.Element[] =>
    errorArr
      .filter((i: IErrorInsightArray) =>
        i.query_string.toLowerCase().includes(search)
      )
      .map((erroredInsight: IErrorInsightArray, index: number) =>
        erroredInsight ? (
          <Grid
            className="insights-list"
            container
            key={index}
            style={{
              margin: "10px 0px",
              color: "#B00020"
            }}
          >
            <Grid item key={index} md={4}>
              <InsightsQuery
                query={erroredInsight.query_string}
                length={55}
              ></InsightsQuery>
            </Grid>
            <Grid item key={index} md={2}>
              {erroredInsight.location_name}
            </Grid>
            <Grid item key={index} md={2}>
              {erroredInsight.language}
            </Grid>
            <Grid item key={index} md={2}>
              {dayjs(erroredInsight.created_at).format("YYYY/MM/DD")}
            </Grid>
            <Grid item key={index} md={2}>
              <Status
                erroredInsight={erroredInsight}
                status={erroredInsight.status}
                percentComplete={0}
                daysSince={computeDaysBetween(
                  new Date(),
                  new Date(erroredInsight.created_at)
                )}
              />
            </Grid>
          </Grid>
        ) : (
          <></>
        )
      );

  const getMoreInsights = async () => {
    if (loading) {
      return;
    }
    setLoadingAdditional(true);
    const currentPage = insightPage + 1;
    setInsightPage(currentPage);
    let pi = await getPaginatedInsights(
      selectedPidObject.id,
      currentPage,
      limit
    );
    if (pi !== null && pi.length < 10) {
      setInsightHasMore(false);
    } else if (pi === null) {
      setInsightHasMore(false);
      pi = [];
    }
    const mi = insights;
    const newInsightArray = mi
      .concat(pi)
      .filter(
        (value: IInsights, index: number, self: IInsights[]) =>
          self.findIndex((v: IInsights) => v.id === value.id) === index
      );
    setInsights(newInsightArray);
  };

  const handleSearch = async (searchTerm: String) => {
    setSearch(searchTerm.toLowerCase());
    if (searchTerm.length > 1) {
      const searchInsights = await getInsightsBySearch(
        selectedPidObject.id,
        `%${searchTerm}%`
      );
      const newInsightArray = insights
        .concat(searchInsights)
        .filter(
          (value: IInsights, index: number, self: IInsights[]) =>
            self.findIndex((v: IInsights) => v.id === value.id) === index
        );
      setInsights(newInsightArray);
    }
  };

  const debouncedHandleSearch = debounce(handleSearch, 250);

  return (
    <div className="insight-table-container">
      <input
        id="search"
        type="text"
        className={"form-control"}
        placeholder="Search by Topic..."
        onChange={(event: { target: { value: string } }) => {
          debouncedHandleSearch(event.target.value);
        }}
        style={{ width: "50%" }}
      ></input>
      <Grid container>
        <Grid
          container
          sx={{
            fontWeight: "bold",
            padding: "10px",
            boxShadow: shadow
              ? "0px 8px 4px -4px rgba(55, 63, 80, 0.25)"
              : "none"
          }}
        >
          <Grid item md={4}>
            Topic
          </Grid>
          <Grid item md={2}>
            Location
          </Grid>
          <Grid item md={2}>
            Language
          </Grid>
          <Grid item md={2}>
            Date Generated
          </Grid>
          <Grid item md={2}>
            Status
          </Grid>
        </Grid>
      </Grid>
      {loading ? (
        <div
          style={{
            display: "flex",
            flexFlow: "column nowrap",
            alignItems: "center",
            justifyContent: "center",
            width: "100%"
          }}
        >
          <DJLoadingGif />
        </div>
      ) : (
        <div id="scrollable-div" className="insights-list-container">
          <InfiniteScroll
            dataLength={insights.length}
            next={getMoreInsights}
            hasMore={insightHasMore}
            scrollThreshold={0.6}
            scrollableTarget="scrollable-div"
            loader={
              <InfinityScrollingLoading
                parentLoading={!loadingAdditional}
              ></InfinityScrollingLoading>
            }
          >
            {}
            {erroredInsightsList()}
            {pendingInsightsList()}
            {insightsList()}
          </InfiniteScroll>
        </div>
      )}
    </div>
  );
};

export default InsightsTable;
