import React, { memo, useMemo } from "react";
import clsx from "clsx";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { ScoreType } from "@/interfaces/topic-authority.interface";

import styles from "./PercentageChange.module.scss";

interface IPercentageChangeProps {
  value?: number;
  type?: ScoreType;
  text?: string;
}

function PercentageChange({
  value,
  type,
  text = "Percentage Change"
}: IPercentageChangeProps) {
  const icon = useMemo(() => {
    if (type === "up") {
      return <ArrowUpwardIcon />;
    }

    if (type === "down") {
      return <ArrowDownwardIcon />;
    }

    return null;
  }, [type]);

  return (
    <div>
      <div
        className={clsx(
          styles.param,
          type === "up" && styles.paramUp,
          type === "down" && styles.paramDown,
          type === "noChanges" && styles.paramNoChanges
        )}
      >
        {icon}
        {value ? `${Math.trunc(value < 0 ? -1 * value : value)}%` : "-"}
      </div>
      <div className={styles.paramText}>{text}</div>
    </div>
  );
}

export default memo(PercentageChange);
