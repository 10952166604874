import { useState, useEffect, CSSProperties } from "react";

import { Form, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import isMFAValidated from "../../../routines/get-mfa-validated";
import { useAppStore } from "@/hooks";

import DJLogo from "../../../assets/DJ-Horizontal-registered.png";

import authorizeMfa from "@/routines/authorize-mfa";
import { IAppStore } from "@/interfaces/app-store.interface";
import AlertBanner from "@/components/shared/AlertBanner";

const imageStyle: CSSProperties = {
  padding: "10px",
  width: "300px"
};

const OtpElement = () => {
  const [disabled, setDisabled] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const validated = await isMFAValidated();
      if (validated) {
        navigate("/home");
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { setShowAlertBanner } = useAppStore((store: IAppStore) => ({
    setShowAlertBanner: store.setShowAlertBanner
  }));

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const code = event.target[0].value;
    if (code.length === 6) {
      setDisabled(true);
      await authorizeMfa(code)
        .then((res) => {
          if (res) {
            setDisabled(false);
            navigate("/home");
            return;
          }
          setDisabled(false);
          setShowAlertBanner(true, {
            message:
              "There was an error validating your multifactor authentication. Please ensure you enter the correct code and try again.",
            severity: "error"
          });
        })
        .catch(() => {
          setDisabled(false);
          setShowAlertBanner(true, {
            message:
              "There was an error validating your multifactor authentication. Please ensure you enter the correct code and try again.",
            severity: "error"
          });
        });
    } else {
      setShowAlertBanner(true, {
        message:
          "MFA Codes are six digits long, please ensure you enter the correct code and try again.",
        severity: "error"
      });
    }
  };

  return (
    <div className="h-100 d-lg-flex d-lg-block bg-white justify-content-center align-items-center col-lg-12">
      <div>
        <Form onSubmit={handleSubmit}>
          <div className="d-flex justify-content-center align-items-center">
            <img
              style={{ ...imageStyle }}
              className="login-logo"
              src={DJLogo}
              alt="DJ Logo"
            />{" "}
          </div>
          <h4>Enter your 2-step verification code</h4>
          <div style={{ marginBottom: "10px" }}>
            <p>
              <b>Enter the six-digit code generated by your phone</b>
            </p>
          </div>

          <Form.Group
            className="mb-12"
            controlId="mfaConfirm"
            style={{ paddingBottom: "5px" }}
          >
            <Form.Control
              type="text"
              pattern="[0-9]{6}"
              maxLength={6}
              placeholder="ex: 123456"
              className="login-inputs"
            />
          </Form.Group>

          <Button
            className={
              disabled
                ? "disabled-profile-update-button"
                : "profile-update-button"
            }
            type="submit"
            disabled={disabled}
          >
            Verify
          </Button>
        </Form>
      </div>
      <AlertBanner />
    </div>
  );
};

OtpElement.propTypes = {};

OtpElement.defaultProps = {};

export default OtpElement;
