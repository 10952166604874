import React, { useState, useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { v4 as uuidv4 } from "uuid";

import { IBrief } from "../../../../interfaces/brief.interface";

import BriefRow from "./BriefRow";
import { getArchivedBriefs } from "../../../../api/get";

import SkeletonRow from "./components/SkeletonRow";
import InfinityScrollingLoading from "./components/InfinityScrollingLoading";
import { useOutOfBounds } from "../../../../hooks/outOfBounds";
import { useAppStore } from "../../../../hooks";

import { IAppStore } from "../../../../interfaces/app-store.interface";

const ArchivedBriefTable = (props: { switchTable: Function }) => {
  const [loading, setLoading] = useState(true);
  const [briefs, setBriefs] = useState([] as IBrief[]);
  const [getFirstPage, setGetFirstPage] = useState(true);
  const [loadingAdditional, setLoadingAdditional] = useState(false);
  const [componentRef, outOfBounds] = useOutOfBounds(true, true, false, false);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");

  const { selectedPidObject } = useAppStore((store: IAppStore) => ({
    selectedPidObject: store.selectedPidObject
  }));

  const getMoreBriefs = async () => {
    if (loading) {
      return;
    }
    setLoadingAdditional(true);
    const currentPage = page + 1;
    setPage(currentPage);
    let pb = await getArchivedBriefs(selectedPidObject.id, currentPage, 10);
    if (pb !== null && pb.length < 10) {
      setHasMore(false);
    } else if (pb === null) {
      setHasMore(false);
      pb = [] as IBrief[];
    }
    const br = briefs;
    const newBriefArray = br.concat(pb);
    setBriefs(newBriefArray);
  };

  const checkViewport = () => {
    const oob = outOfBounds as {
      top: number;
      bottom: number;
      left: number;
      right: number;
    };
    if (!loading && hasMore && oob.bottom === 0 && oob.top === 0) {
      getMoreBriefs();
    }
  };

  const archiveBrief = (briefId: number) => {
    const br = briefs;
    br.forEach((i, index) => {
      if (i.id === briefId) {
        br.splice(index, 1);
      }
    });
    setBriefs([...br]);
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value.toLowerCase());
    checkViewport();
  };

  const data = {
    briefs: briefs.filter((item) => item.h1.toLowerCase().includes(search))
  };

  useEffect(() => {
    const fetchData = async () => {
      if (getFirstPage) {
        setGetFirstPage(false);
        let briefsRows = await getArchivedBriefs(selectedPidObject.id, 1, 10);
        if (briefsRows !== null && briefsRows.length < 10) {
          setHasMore(false);
          setLoadingAdditional(false);
        } else if (briefsRows === null) {
          setHasMore(false);
          briefsRows = [];
        }
        setBriefs(briefsRows);
        setLoading(false);
      }
    };
    if (selectedPidObject?.id) {
      fetchData();
    }
    checkViewport();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, outOfBounds, selectedPidObject]);

  const switchTable = () => {
    props.switchTable();
  };

  return (
    <div
      ref={componentRef as React.RefObject<HTMLDivElement>}
      style={{ paddingTop: "0" }}
    >
      <div className="dataTables_wrapper dt-bootstrap4" id="consumerBrief">
        <div className="row justify-content-between">
          <div className="col-sm-4 wrapper">
            <input
              id="search"
              type="text"
              className={"form-control"}
              placeholder="Search..."
              onChange={handleSearch}
            ></input>
          </div>
          <div className="col-sm-4">
            <button className="btn btn-outline-primary" onClick={switchTable}>
              Show Content Briefs
            </button>
          </div>
        </div>

        <br></br>

        <InfiniteScroll
          dataLength={briefs.length}
          next={getMoreBriefs}
          hasMore={hasMore}
          loader={
            <InfinityScrollingLoading
              parentLoading={!loadingAdditional}
            ></InfinityScrollingLoading>
          }
          scrollableTarget="scrollableArea"
          style={{ overflow: "visible" }}
        >
          <table id="briefTable" className="table table-hover dtables">
            <thead>
              <tr>
                <th style={{ width: "20%" }}>Archived Content Briefs</th>
                <th style={{ width: "20%" }}>Status</th>
                <th style={{ width: "20%" }}>Assignee</th>
                <th style={{ width: "15%" }}>Type </th>
                <th style={{ width: "20%" }}>Progress</th>
                <th style={{ width: "20%" }}>Date Generated</th>
              </tr>
            </thead>
            <tbody style={{ overflow: "visible" }}>
              {loading &&
                [...Array(10)].map((_elementInArray, index) => (
                  <SkeletonRow key={index} columns={5}></SkeletonRow>
                ))}
              {!loading &&
                data.briefs.map((brief) => (
                  <BriefRow
                    key={`${brief.id}-${uuidv4()}`}
                    brief={brief}
                    percentage={brief.percent_complete}
                    status={brief.status}
                    archiveBrief={archiveBrief}
                    archiveTable={true}
                    pid={selectedPidObject.id}
                    briefs={briefs}
                    setBriefs={setBriefs}
                  ></BriefRow>
                ))}
            </tbody>
          </table>
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default ArchivedBriefTable;
