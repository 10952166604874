import { IDemandaAIStore } from "@/interfaces/demanda-ai-store.interface";
import { IDemandaAIMessage } from "@/interfaces/demanda-ai.interface";

const createDemandaAIStore: () => IDemandaAIStore = () => ({
  threadId: undefined,
  messages: [] as IDemandaAIMessage[],
  setThreadId(value: string) {
    this.threadId = value;
  },
  setMessages(messages) {
    this.messages = messages;
  },
  setMessage(message) {
    this.messages = [...this.messages, message];
  },
  updateLastMessage(message) {
    const lastMessage = this.messages[this.messages.length - 1];
    lastMessage.message += message.message;
    const messagesWithoutLast = this.messages.slice(
      0,
      this.messages.length - 1
    );

    this.messages = [...messagesWithoutLast, lastMessage];
  }
});

export default createDemandaAIStore;
